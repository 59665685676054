import { css } from '@emotion/react';
import { labelStyles } from '@zapier/style-encapsulation';
import { Animation, Colors, Shadows } from '../../../theme';
import { createUniqueCustomProperties } from '../../../utils/createUniqueCustomProperty';

const Vars = createUniqueCustomProperties('Button', [
  'baseBackgroundColor',
  'baseBorderColor',
  'baseColor',
  'disabledBackgroundColor',
  'disabledBorderColor',
  'disabledColor',
  'disabledOutlineColor',
  'hoverBackgroundColor',
  'hoverBorderColor',
  'hoverColor',
  'hoverOutlineColor',
  'iconMargin',
  'focusBackgroundColor',
  'focusBorderColor',
  'focusColor',
  'focusOutlineColor',
  'selectedBackgroundColor',
  'selectedBorderColor',
  'selectedColor',
  'selectedOutlineColor',
  'buttonContentPadding',
]);

export const Styles = labelStyles('BaseButton', {
  root: css`
    // All of the CSS Custom Properties that are used by this
    // component, ensured to be unique. Most are color related
    // and are set below via '[data-color]' selectors.
    ${Vars.baseBackgroundColor}: transparent;
    ${Vars.baseBorderColor}: transparent;
    ${Vars.baseColor}: #000;
    ${Vars.disabledBackgroundColor}: var(--zds-background-strongest, ${Colors.GrayWarm2});
    ${Vars.disabledBorderColor}: var(--zds-background-strongest, ${Colors.GrayWarm4});
    ${Vars.disabledColor}: var(--zds-text-weakest, ${Colors.GrayWarm5});
    ${Vars.disabledOutlineColor}: transparent;
    ${Vars.hoverBackgroundColor}: var(${Vars.baseBackgroundColor});
    ${Vars.hoverBorderColor}: var(${Vars.baseBorderColor});
    ${Vars.hoverColor}: var(${Vars.baseColor});
    ${Vars.hoverOutlineColor}: transparent;
    ${Vars.iconMargin}: 6px;
    ${Vars.focusBackgroundColor}: var(${Vars.baseBackgroundColor});
    ${Vars.focusBorderColor}: transparent;
    ${Vars.focusColor}: var(${Vars.baseColor});
    ${Vars.focusOutlineColor}: var(${Vars.focusBackgroundColor});
    ${Vars.selectedBackgroundColor}: var(${Vars.baseBackgroundColor});
    ${Vars.selectedBorderColor}: transparent;
    ${Vars.selectedColor}: var(${Vars.baseColor});
    ${Vars.selectedOutlineColor}: transparent;
    ${Vars.buttonContentPadding}: 0 10px;

    background-color: var(${Vars.baseBackgroundColor});
    border-color: var(${Vars.baseBorderColor});
    color: var(${Vars.baseColor});

    align-items: center;
    appearance: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    // Safari applies 2px margin to buttons this clears it
    margin: 0;
    min-width: 0;
    opacity: 1;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    transition: all 200ms ease-in-out;
    vertical-align: middle;
    white-space: nowrap;

    &::-moz-focus-inner {
      padding: 0;
      border: 0;
    }

    // This is the outline that renders around the button when
    // its focused. Its a pseudo element to preserve transparency;
    // between the edge of the button and the edge of the outline.
    // Its defined here vs only in ':focus' so that it can transition
    // between states.
    &::before {
      content: '';
      position: absolute;
      pointer-events: none;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 2px solid transparent;
      border-radius: 5px;
      transition-property: all;
      transition-timing-function: ${Animation.transitionTimingFunction};
      transition-duration: 200ms;
    }

    &:hover:not([disabled]),
    a:hover & {
      background-color: var(${Vars.hoverBackgroundColor});
      border-color: var(${Vars.hoverBorderColor});
      /* TODO: ask Christophe to create a variable with a 'none' value */
      box-shadow: var(--zds-radius-none, ${Shadows.elevation6});
      color: var(${Vars.hoverColor});
    }

    &:focus-visible:not([disabled]),
    a:focus-visible & {
      background-color: var(${Vars.focusBackgroundColor});
      border-color: var(${Vars.focusBorderColor});
      color: var(${Vars.focusColor});
      outline: none;
      &::before {
        border-color: var(${Vars.focusOutlineColor});
        top: -4px;
        right: -4px;
        bottom: -4px;
        left: -4px;
      }
    }

    &[data-selected]:not([data-color='button-nav']) {
      background-color: var(${Vars.selectedBackgroundColor});
      border-color: var(${Vars.selectedBorderColor});
      color: var(${Vars.selectedColor});
      // Hide :focus outline on selected buttons
      &::before {
        content: none;
      }
    }

    &[data-color='primary'] {
      ${Vars.baseBackgroundColor}: var(--zds-ui-primary-stronger);
      ${Vars.baseColor}: var(--zds-background-weaker);
      ${Vars.hoverBackgroundColor}: var(--zds-ui-primary-strongest);

      &[data-size='compact'] {
        font: var(--zds-minimal-print-3);
      }
      &[data-size='small'] {
        font: var(--zds-body-3);
      }
      &[data-size='medium'] {
        font: var(--zds-body-3);
      }
      &[data-size='large'] {
        font: var(--zds-paragraph-header-3);
      }
    }

    &[data-color='danger'] {
      ${Vars.baseBackgroundColor}: var(--zds-status-error-stronger);
      ${Vars.baseColor}: var(--zds-background-weaker);
      ${Vars.hoverBackgroundColor}: var(--zds-status-error-strongest);
      ${Vars.hoverColor}: var(--zds-background-weaker);
      ${Vars.focusColor}: var(--zds-background-weaker);
      ${Vars.focusOutlineColor}: var(--zds-ui-primary-stronger);

      &[data-size='compact'] {
        font: var(--zds-minimal-print-2);
      }
      &[data-size='small'] {
        font: var(--zds-body-3);
      }
      &[data-size='medium'] {
        font: var(--zds-body-3);
      }
      &[data-size='large'] {
        font: var(--zds-paragraph-header-3);
      }
    }

    &[data-color='secondary'] {
      ${Vars.baseBackgroundColor}: var(--zds-ui-primary-weaker);
      ${Vars.baseColor}: var(--zds-ui-primary-strongest);
      ${Vars.hoverBackgroundColor}: var(--zds-ui-primary);
      ${Vars.hoverBorderColor}: var(--zds-ui-primary);
      ${Vars.hoverColor}: var(--zds-background-weaker);
      ${Vars.focusBackgroundColor}: var(--zds-ui-primary-weaker);
      ${Vars.focusColor}: var(--zds-ui-primary-strongest);
      ${Vars.baseBorderColor}: var(--zds-ui-primary-weaker);
      ${Vars.focusOutlineColor}: var(--zds-ui-primary-stronger);

      &[data-size='compact'] {
        font: var(--zds-minimal-print-2);
      }
      &[data-size='small'] {
        font: var(--zds-body-2);
      }
      &[data-size='medium'] {
        font: var(--zds-body-2);
      }
      &[data-size='large'] {
        font: var(--zds-paragraph-header-2);
      }
    }

    &[data-color='tertiary'] {
      ${Vars.baseBorderColor}: var(--zds-stroke-default);
      ${Vars.baseBackgroundColor}: var(--zds-background-weaker);
      ${Vars.baseColor}: var(--zds-text-default);
      ${Vars.hoverBackgroundColor}: var(--zds-background-default);
      ${Vars.hoverColor}: var(--zds-text-default);
      ${Vars.focusBackgroundColor}: var(--zds-background-weaker);
      ${Vars.focusColor}: var(--zds-text-default);
      ${Vars.focusOutlineColor}: var(--zds-ui-primary-stronger);

      &[data-size='compact'] {
        font: var(--zds-minimal-print-2);
      }
      &[data-size='small'] {
        font: var(--zds-body-2);
      }
      &[data-size='medium'] {
        font: var(--zds-body-2);
      }
      &[data-size='large'] {
        font: var(--zds-paragraph-header-2);
      }

      &[disabled] {
        background-color: var(--zds-gray-warm-3);
      }

      &:focus-visible:not([disabled]),
      a:focus-visible & {
        border-color: var(--zds-stroke-weaker);
        outline: none;
      }
    }

    &[data-color='ghost'] {
      ${Vars.baseBorderColor}: transparent;
      ${Vars.baseBackgroundColor}: transparent;
      ${Vars.baseColor}: var(--zds-ui-primary-stronger);
      ${Vars.hoverBackgroundColor}: var(--zds-ui-primary-weakest);
      ${Vars.hoverColor}: var(--zds-ui-primary-stronger);
      ${Vars.focusBackgroundColor}: var(--zds-background-weaker);
      ${Vars.focusColor}: var(--zds-ui-primary-stronger);
      ${Vars.focusOutlineColor}: var(--zds-ui-primary-stronger);


      &[data-size='compact'] {
        font: var(--zds-minimal-print-2);
      }
      &[data-size='small'] {
        font: var(--zds-body-2);
      }
      &[data-size='medium'] {
        font: var(--zds-body-2);
      }
      &[data-size='large'] {
        font: var(--zds-paragraph-header-3);
      }
    }

    &[data-color='icon-alt'] {
      ${Vars.baseBackgroundColor}: var(--zds-ui-primary);
      ${Vars.baseBorderColor}: var(--zds-ui-primary);
      ${Vars.baseColor}: var(--zds-background-weaker);
      ${Vars.hoverColor}: var(--zds-background-weaker);
      ${Vars.hoverBackgroundColor}: var(--zds-ui-primary-stronger);
      ${Vars.focusBackgroundColor}: var(--zds-ui-primary);
      ${Vars.focusBorderColor}: var(--ui-primary);
      ${Vars.focusColor}: var(--zds-background-weaker);
      ${Vars.focusOutlineColor}: var(--zds-ui-primary-stronger);

      &[disabled] {
        background-color: var(--zds-background-strongest);
        border-color: var(--zds-background-strongest);
        color: var(--zds-text-weakest);
        outline-color: var(${Vars.disabledOutlineColor});
      }
    }

    &[data-color='icon-brand'] {
      ${Vars.baseBackgroundColor}: var(--zds-ui-brand);
      ${Vars.baseColor}: var(--zds-text-inverted);
      ${Vars.hoverBackgroundColor}: var(--zds-ui-brand-stronger);
      ${Vars.focusOutlineColor}: var(--zds-ui-primary-stronger);

      &[disabled] {
        background-color: var(--zds-background-stronger);
        border-color: var(--zds-background-stronger);
        color: var(--zds-text-weaker);
        outline-color: var(${Vars.disabledOutlineColor});
      }
    }

    &[data-color='icon-ghost'] {
      ${Vars.baseColor}: var(--zds-gray-warm-8);
      ${Vars.hoverBackgroundColor}: var(--zds-ui-primary-weakest);
      ${Vars.hoverColor}: var(--zds-gray-warm-8);
      ${Vars.focusBackgroundColor}: var(--zds-background-weaker);
      ${Vars.focusColor}: var(--zds-gray-warm-8);
      ${Vars.focusOutlineColor}: var(--zds-ui-primary-stronger);

      &[disabled] {
        background-color: var(--zds-background-stronger);
        border-color: var(--zds-background-stronger);
        color: var(--zds-text-weaker);
        outline-color: var(${Vars.disabledOutlineColor});
      }
    }

    &[data-color='icon-primary'] {
      ${Vars.baseBackgroundColor}: var(--zds-ui-primary-stronger);
      ${Vars.baseBorderColor}: var(--zds-ui-primary-stronger);
      ${Vars.baseColor}: var(--zds-background-weaker);
      ${Vars.hoverColor}: var(--zds-background-weaker);
      ${Vars.hoverBackgroundColor}: var(--zds-ui-primary-strongest);
      ${Vars.focusBorderColor}: var(--zds-ui-primary-stronger);
      ${Vars.focusColor}: var(--zds-background-weaker);
      ${Vars.focusOutlineColor}: var(--zds-ui-primary-stronger);

      font: var(--zds-body-2);

      &[disabled] {
        background-color: var(--zds-background-strongest);
        border-color: var(--zds-background-strongest);
        color: var(--zds-text-weakest);
        outline-color: var(${Vars.disabledOutlineColor});
      }
    }

    &[data-color='icon-secondary'] {
      ${Vars.baseBackgroundColor}: var(--zds-ui-primary-weaker);
      ${Vars.baseBorderColor}: var(--zds-ui-primary-weaker);
      ${Vars.baseColor}: var(--zds-ui-primary-stronger);
      ${Vars.hoverBackgroundColor}: var(--zds-ui-primary);
      ${Vars.hoverColor}: var(--zds-ui-primary-strongest);
      ${Vars.focusBorderColor}: var(--zds-ui-primary-weaker);
      ${Vars.focusColor}: var(--zds-ui-primary-stronger);
      ${Vars.focusOutlineColor}: var(--zds-ui-primary-stronger);

      font: var(--zds-body-2);

      &[disabled] {
        background-color: var(--zds-background-strongest);
        border-color: var(--zds-background-strongest);
        color: var(--zds-text-weakest);
        outline-color: var(${Vars.disabledOutlineColor});
      }
    }

    &[data-color='icon-tertiary'] {
      ${Vars.baseBackgroundColor}: var(--zds-background-weaker);
      ${Vars.baseColor}: var(--zds-text-weaker);
      ${Vars.baseBorderColor}: var(--zds-stroke-weaker);
      ${Vars.hoverBackgroundColor}: var(--zds-background-default);
      ${Vars.hoverColor}: var(--zds-text-weaker);
      ${Vars.focusBackgroundColor}: var(--zds-background-weaker);
      ${Vars.focusColor}: var(--zds-text-weaker);
      ${Vars.focusOutlineColor}: var(--zds-ui-primary-stronger);

      font: var(--zds-body-2);

      &[disabled] {
        background-color: var(--zds-background-strongest);
        border: var(--zds-background-strongest);
        color: var(--zds-text-weakest);
      }
    }

    &[data-color='number'] {
      ${Vars.baseBackgroundColor}: var(--zds-background-weaker);
      ${Vars.baseBorderColor}: var(--zds-stroke-default);
      ${Vars.baseColor}: var(--zds-text-weaker);
      ${Vars.hoverBackgroundColor}: var(--zds-background-stronger);
      ${Vars.hoverBorderColor}: var(--zds-stroke-default);
      ${Vars.hoverColor}: var(--zds-text-weaker);
      ${Vars.focusBorderColor}: var(--zds-stroke-default);
      ${Vars.focusColor}: var(--zds-text-default);
      ${Vars.focusOutlineColor}: var(--zds-ui-primary-stronger);

      font: var(--zds-body-2);

      &[data-selected] {
        ${Vars.baseBackgroundColor}: var(--zds-ui-primary-weaker);
        ${Vars.baseBorderColor}: var(--zds-ui-primary-weaker);
        ${Vars.baseColor}: var(--zds-ui-primary-strongest);

        /* Selected buttons get their before block removed further down below  */
        ::before {
          content: '';
        }

        &:hover {
          color: var(--zds-text-inverted);
          background-color: var(--zds-ui-primary);
        }

        &[disabled] {
          background-color: var(--zds-background-strongest);
          border-color: var(--zds-background-strongest);
          color: var(--zds-gray-warm-7);
        }
      }

      &[disabled] {
        background-color: var(--zds-background-strongest);
        border-color: var(--zds-background-strongest);
        color: var(--zds-gray-warm-7);
      }
    }

    &[data-color='primary-dark'],
    &[data-color='icon-primary-dark'] {
      ${Vars.baseBackgroundColor}: var(--zds-brand-almost-white);
      ${Vars.baseColor}: var(--zds-ui-primary-stronger);
      ${Vars.hoverColor}: var(--zds-ui-primary-strongest);
      ${Vars.hoverBackgroundColor}: var(--zds-ui-primary-weakest);
      ${Vars.focusColor}: var(--zds-ui-primary-strongest);
      ${Vars.focusOutlineColor}: var(--zds-background-weaker);

      &[data-size='compact'] {
        font: var(--zds-minimal-print-3);
      }
      &[data-size='small'] {
        font: var(--zds-body-3);
      }
      &[data-size='medium'] {
        font: var(--zds-body-3);
      }
      &[data-size='large'] {
        font: var(--zds-paragraph-header-3);
      }
    }

    &[data-color='secondary-dark'],
    &[data-color='icon-secondary-dark'] {
      ${Vars.baseBackgroundColor}: transparent;
      ${Vars.baseColor}: ${Colors.GrayWarm1};
      ${Vars.baseBorderColor}: ${Colors.GrayWarm1};
      ${Vars.hoverColor}: ${Colors.GrayWarm10};
      ${Vars.hoverBackgroundColor}: ${Colors.UiPrimaryWeakest};
      ${Vars.hoverBorderColor}: ${Colors.UiPrimaryWeakest};
      ${Vars.focusColor}: ${Colors.GrayWarm10};
      ${Vars.focusOutlineColor}: ${Colors.UiPrimaryWeakest};
      ${Vars.focusBackgroundColor}: ${Colors.UiPrimaryWeakest};

      &[data-size='compact'] {
        font: var(--zds-minimal-print-2);
      }
      &[data-size='small'] {
        font: var(--zds-body-3);
      }
      &[data-size='medium'] {
        font: var(--zds-body-3);
      }
      &[data-size='large'] {
        font: var(--zds-paragraph-header-3);
      }
    }

    &[data-color='button-nav'] {
      ${Vars.baseBorderColor}: var(--zds-stroke-weaker);
      ${Vars.baseBackgroundColor}: var(--zds-background-weaker);
      ${Vars.baseColor}: var(--zds-text-default);
      ${Vars.hoverBackgroundColor}: var(--zds-background-default);
      ${Vars.hoverColor}: var(--zds-text-default);
      ${Vars.focusBackgroundColor}: var(--zds-background-weaker);
      ${Vars.focusColor}: var(--zds-text-default);
      ${Vars.focusOutlineColor}: var(--zds-ui-primary-stronger);
      ${Vars.focusBorderColor}: var(--zds-gray-warm-2);

      font: var(--zds-body-2);
      border-radius: var(--zds-radius-pill);
      &::before {
        border-radius: var(--zds-radius-pill);
      }
      &[data-selected] {
        ${Vars.baseBorderColor}: var(--zds-gray-warm-9);
        ${Vars.baseBackgroundColor}: var(--zds-gray-warm-9);
        ${Vars.baseColor}: var(--zds-text-inverted);
        ${Vars.hoverBackgroundColor}: var(--zds-gray-warm-8);
        ${Vars.hoverColor}: var(--zds-text-inverted);
        ${Vars.focusBackgroundColor}: var(--zds-gray-warm-8);
        ${Vars.focusColor}: var(--zds-text-inverted);
        ${Vars.focusOutlineColor}: var(--zds-ui-gray-warm-8);
      }
    }

    &[data-color='brand'],
    &[data-color='brand-nav'] {
      ${Vars.baseBackgroundColor}: var(--zds-ui-brand);
      ${Vars.baseColor}: var(--zds-text-inverted);
      ${Vars.baseBorderColor}: var(--zds-ui-brand);
      ${Vars.hoverColor}: var(--zds-background-weaker);
      ${Vars.hoverBackgroundColor}: var(--zds-ui-brand-stronger);
      ${Vars.focusColor}: var(--zds-text-inverted);
      ${Vars.focusOutlineColor}: var(--zds-ui-primary);
      ${Vars.focusBackgroundColor}: var(--zds-ui-brand);
      ${Vars.focusBorderColor}: var(--zds-ui-brand);

      &:hover:not([disabled]),
      a:hover & {
        border-color: var(--zds-ui-brand-stronger);
      }
    }

    &[data-color='brand'] {
      &[data-size='compact'] {
        font: var(--zds-minimal-print-3);
      }
      &[data-size='small'] {
        font: var(--zds-body-3);
        border-radius: var(--zds-radius-large);
        &::before {
          border-radius: var(--zds-radius-small);
        }
      }
      &[data-size='medium'] {
        font: var(--zds-body-3);
        border-radius: var(--zds-radius-large);
        &::before {
          border-radius: var(--zds-radius-small);
        }
      }
      &[data-size='large'] {
        font: var(--zds-paragraph-header-3);
        border-radius: var(--zds-radius-large);
        &::before {
          border-radius: var(--zds-radius-small);
        }
      }
    }

    &[data-color='brand-nav'] {
      ${Vars.iconMargin}: 0;
      &[data-size='medium'] {
        font: var(--zds-body-3);
        border-radius: var(--zds-radius-large);
        &::before {
          border-radius: var(--zds-radius-small);
        }
      }
    }

    &[disabled] {
      cursor: not-allowed;
      background-color: var(${Vars.disabledBackgroundColor});
      border-color: var(${Vars.disabledBorderColor});
      color: var(${Vars.disabledColor});
      outline-color: var(${Vars.disabledOutlineColor});
    }

    &[data-full-width] {
      width: 100%;
    }

    &[data-shadowed] {
      box-shadow: ${Shadows.elevation5};
    }

    &[data-size='compact'] {
      ${Vars.buttonContentPadding}: 0 var(--zds-space-12);
      height: var(--zds-size-small);

      &[data-icon] {
        /* Last value accounts for empty space inside svg */
        ${Vars.buttonContentPadding}: 0 var(--zds-space-12) 0 var(--zds-space-8);
      }

      &[data-square] {
        width: var(--zds-size-small);
      }
    }

    &[data-size='small'] {
      ${Vars.buttonContentPadding}: 0 var(--zds-space-12);
      height: var(--zds-size-small);

      &[data-icon] {
        ${Vars.buttonContentPadding}: 0 var(--zds-space-12) 0 var(--zds-space-8);
      }

      &[data-square] {
        width: var(--zds-size-small);
        height: var(--zds-size-small);
      }
      &[data-color='button-nav'] {
        ${Vars.buttonContentPadding}: 0 var(--zds-space-12);
      }
    }

    &[data-size='medium'] {
      ${Vars.buttonContentPadding}: 0 var(--zds-space-12);
      height: var(--zds-size-medium);

      &[data-icon] {
        ${Vars.buttonContentPadding}: 0 var(--zds-space-12) 0 var(--zds-space-8);
        &[data-color='brand-nav'] {
          ${Vars.buttonContentPadding}: 0 var(--zds-space-12);
        }
      }

      &[data-square] {
        width: var(--zds-size-medium);
      }
    }

    &[data-size='large'] {
      ${Vars.buttonContentPadding}: 0 var(--zds-space-12);
      height: var(--zds-size-large);

      &[data-icon] {
        ${Vars.buttonContentPadding}: 0 var(--zds-space-12) 0 var(--zds-space-8);
      }

      &[data-square] {
        width: var(--zds-size-large);
      }
    }

    &[data-unpadded],
    &[data-square] {
      ${Vars.buttonContentPadding}: 0 !important;
    }

    &[data-pill] {
      border-radius: var(--zds-radius-pill) !important;

      &::before {
        border-radius: var(--zds-radius-pill) !important;
      }
    }

    /*
    IconButton size styles
    */
      &[data-size='compact'],&[data-size='small'] {
        [data-testid='iconContainer'] {
          width: var(--zds-size-20);
          height: var(--zds-size-20);
        }
      }
      &[data-size='medium'] {
        [data-testid='iconContainer'] {
          width: var(--zds-size-24);
          height: var(--zds-size-24);
        }
      }
      &[data-size='large'] {
        [data-testid='iconContainer'] {
          width: var(--zds-size-32);
          height: var(--zds-size-32);
        }
      }
    */
  `,
  buttonContent: css`
    display: flex;
    align-items: center;
    min-width: 0;
    padding: var(${Vars.buttonContentPadding});
  `,
  buttonText: css`
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  `,
  buttonIcon: (hasSibling: boolean) => css`
    display: block;
    flex: none;
    ${hasSibling && `margin-right: var(${Vars.iconMargin});`}
  `,
});
