import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgArrowResizeDiagonalRight = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m17.876 6.124-6.506-.566-1.845 1.846 6.498.573.573 6.498 1.846-1.846-.566-6.505ZM7.404 9.525l-1.846 1.846.566 6.505 6.505.566 1.846-1.846-6.499-.573-.572-6.498Z"
    />
  </svg>
);
export default SvgArrowResizeDiagonalRight;
