import React, { ComponentProps } from 'react';

import { Link as DSLink } from '@zapier/design-system';

import AppLink from './AppLink';
import BlockLink from './BlockLink';

type Props = ComponentProps<typeof BlockLink | typeof DSLink> & {
  anchor?: string;
};

const defaultProps = {
  anchor: undefined,
};

function Link(props: Props) {
  const { anchor = defaultProps.anchor, children, href, onClick } = props;
  const isBlockLink = typeof children !== 'string';
  const LinkComponent = isBlockLink ? BlockLink : DSLink;
  const hrefComplete = href ? `${href}${anchor || ''}` : undefined;

  return (
    <LinkComponent
      {...props}
      component={AppLink}
      href={hrefComplete}
      onClick={onClick}
    >
      {children}
    </LinkComponent>
  );
}

Link.defaultProps = defaultProps;

export default Link;
