/** @jsx jsx */
import { Children, useState, useRef, useEffect } from 'react';
import { css, jsx, keyframes } from '@emotion/react';

import { labelStyles } from '@zapier/style-encapsulation';

import { Animation, Zindexes } from '../../../theme';
import { IconButton } from '../../navigation/IconButton';

import { useUnscrollableBody } from './useUnscrollableBody';
import { useFocusTrap } from './useFocusTrap';

type Props = {
  /**
   * The description of the modal modal to be read by screen readers when `Modal` opens.
   */
  'aria-label'?: string;
  /**
   * Indicates whether `Modal` can be closed. When `false`,
   * no close button will render and clicking the overlay will not
   * close `Modal`. The code rendering `Modal` must manually close it.
   */
  canClose?: boolean;
  /**
   * Indicates whether `document.body` can still be scrolled.
   */
  canScrollBody?: boolean;
  /**
   * The content to render inside of `Modal`.
   */
  children: JSX.Element;
  /**
   * Function called when the modal has finished closing.
   */
  onClosed: () => void;
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const Styles = labelStyles('Modal', {
  root: css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${Zindexes.modalOverlay};
    opacity: 0;
    display: grid;
    align-items: center;
    justify-items: center;
  `,

  rootIn: css`
    animation: ${fadeIn} ${Animation.transitionDuration}
      ${Animation.transitionTimingFunction} forwards;

    @media (prefers-reduced-motion) {
      animation-duration: 0s;
    }
  `,

  rootOut: css`
    animation: ${fadeOut} ${Animation.transitionDuration}
      ${Animation.transitionTimingFunction} forwards;

    @media (prefers-reduced-motion) {
      animation-duration: 0s;
    }
  `,

  overlay: css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--zds-background-overlay);
    opacity: 0.8;
    z-index: 1;
  `,

  modal: css`
    position: relative;
    z-index: 2;
    max-height: 100vh;
    // Prevent margin collapsing of children
    padding: 1px;
    // Ensure children shink by default
    display: flex;
  `,
});

export const Modal = ({
  'aria-label': ariaLabel = undefined,
  canClose = true,
  canScrollBody,
  children,
  onClosed,
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const modalRef = useRef<HTMLDivElement>(null);

  useUnscrollableBody({ shouldAllowScroll: canScrollBody === true });

  // Handle close request and kick off closing animation.
  const onClose = () => {
    if (canClose) {
      setIsOpen(false);
    }
  };

  // Once the closing animation has completed, call `onClosed`.
  const onAnimationEnd = () => {
    if (isOpen === false) {
      onClosed();
    }
  };

  // Close `Modal` when escape key is pressed. No need
  // to worry about `canClose` here since it's handled elsewhere.
  useEffect(() => {
    const onEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keyup', onEscape, { capture: true });
    return () => {
      window.removeEventListener('keyup', onEscape, { capture: true });
    };
  });

  // Keep focus to nodes within the modal.
  useFocusTrap(modalRef);

  const overlay = (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div css={Styles.overlay} data-testid="ModalOverlay" onClick={onClose} />
  );

  const closeButton = !!canClose && (
    <IconButton
      aria-label="Close modal"
      color="icon-ghost"
      icon="formX"
      onClick={onClose}
      size="medium"
    />
  );

  // Pass `closeButton` as a prop to the only child. This allows
  // the child to render `closeButton` wherever it wants while still
  // managing styling and behavior within `Modal`, and it sidesteps
  // requiring a function as a child.
  const onlyChild = children && Children.only(children);
  const modalChildren = {
    ...onlyChild,
    props: {
      ...(children.props || {}),
      closeButton,
    },
  };

  const modal = (
    <div
      aria-label={ariaLabel}
      aria-live="polite"
      aria-modal="true"
      css={Styles.modal}
      ref={modalRef}
      role="dialog"
      data-zds
    >
      {modalChildren}
    </div>
  );

  return (
    <div
      css={[Styles.root, isOpen ? Styles.rootIn : Styles.rootOut]}
      data-testid="Modal"
      onAnimationEnd={onAnimationEnd}
      data-zds
    >
      {overlay}
      {modal}
    </div>
  );
};
