/** @jsx jsx */

import { jsx, keyframes } from '@emotion/react';
import { labelStyles, withModifier } from '@zapier/style-encapsulation';
import { Colors } from '../../../theme';
import { VisuallyHidden } from '../VisuallyHidden';

type Props = {
  /** Accessibility label for the node */
  'aria-label'?: string;
  /** Diameter and border width of loader. */
  size?: 'small' | 'medium' | 'large';
  /** The color of the spinner. Use `light` when rendering over a dark background. Use `dark` when rendering over a light background. */
  color?: 'light' | 'dark';
};

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const sizes = {
  small: {
    diameter: 'var(--zds-size-small)',
    thickness: 4,
  },
  medium: {
    diameter: 'var(--zds-size-medium)',
    thickness: 6,
  },
  large: {
    diameter: 'var(--zds-size-large)',
    thickness: 10,
  },
};

const mapPropToColorValue = (color: Props['color']) => {
  let returnedColor;
  switch (color) {
    case 'light':
      returnedColor = Colors.PrimeWhite;
      break;
    case 'dark':
      returnedColor = Colors.GrayWarm10;
  }
  return returnedColor;
};

const Styles = labelStyles('Spinner', {
  root: (props: Required<Props>) => [
    {
      animation: `${spin} 0.75s linear infinite`,
      borderStyle: 'solid',
      borderRightColor: 'transparent',
      borderRadius: '50%',
      opacity: 0.7,
    },
    withModifier(props.size, {
      height: sizes[props.size].diameter,
      width: sizes[props.size].diameter,
      borderWidth: `${sizes[props.size].thickness}px`,
    }),
    withModifier(props.color, {
      borderColor: mapPropToColorValue(props.color),
      borderRightColor: 'transparent',
    }),
  ],
});

/**
 * Renders a spinner that indicates that something async is happening.
 */
export const Spinner = ({
  'aria-label': ariaLabel = 'Loading',
  color = 'dark',
  size = 'small',
}: Props) => {
  const props = { ariaLabel, color, size };

  return (
    <div css={Styles.root(props)} data-testid="spinner" role="status" data-zds>
      <VisuallyHidden>{ariaLabel}</VisuallyHidden>
    </div>
  );
};
