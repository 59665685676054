import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgArrowExpand = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M8 6.243 12.243 2l4.242 4.243V9.07L13.24 5.825 13.254 11h-2.008V5.825L8 9.071V6.243ZM8 17.657l4.243 4.242 4.242-4.242v-2.829l-3.245 3.246.007-5.074h-2.008l.007 5.074L8 14.828v2.829Z"
    />
  </svg>
);
export default SvgArrowExpand;
