/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useEffect, useRef } from 'react';

import { Text } from '../../display/Text';
import { useInteractiveTagContext } from './InteractiveTagContext';

const Styles = {
  root: css({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
};

export type InteractiveTagContentProps = {
  /**
   * Optional content to be displayed after the title.
   */
  body?: string;
  /**
   * Title of the tag.
   */
  title: string;
};

/**
 * InteractiveTag content that formats and can truncate a title and body.
 * Must be wrapped by `InteractiveTag` or `InteractiveTagWrapper`.
 */
export const InteractiveTagContent = ({
  body,
  title,
}: InteractiveTagContentProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const { setBody, setIsTruncated, setTitle } = useInteractiveTagContext();

  const checkIsTruncated = () => {
    if (contentRef.current) {
      return (
        (contentRef.current as HTMLElement).scrollWidth >
        (contentRef.current as HTMLElement).clientWidth
      );
    }

    return false;
  };

  useEffect(() => {
    setIsTruncated(checkIsTruncated());
    setBody(body);
    setTitle(title);
  }, []);

  return (
    <div css={Styles.root} ref={contentRef} data-zds>
      <Text color="inherit" type="Body3">
        {title}
        {body ? ': ' : ''}
      </Text>
      {body && (
        <Text color="inherit" type="Body1">
          {body}
        </Text>
      )}
    </div>
  );
};
