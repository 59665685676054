import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscEvergreenAi = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M2 11h4.385A4.615 4.615 0 0 0 11 6.384V2h2v4.384A4.615 4.615 0 0 0 17.616 11H22v2h-4.384A4.615 4.615 0 0 0 13 17.615V22h-2v-4.385A4.615 4.615 0 0 0 6.385 13H2v-2Z"
    />
    <path
      fill="#2D2E2E"
      d="m4 18.586 3.536-3.536 1.414 1.415L5.414 20 4 18.586ZM18.586 4 15.05 7.536l1.414 1.414L20 5.414 18.586 4ZM5.414 4 8.95 7.536 7.535 8.95 4 5.414 5.414 4ZM20 18.586l-3.535-3.536-1.415 1.415L18.586 20 20 18.586Z"
    />
  </svg>
);
export default SvgMiscEvergreenAi;
