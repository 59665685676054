import React from 'react';

import { Icon } from '../../display/Icon';
import type { IconName } from '../../display/Icon';

import styles from './Alert.module.scss';

const STATUS_ICONS: Record<Status, IconName> = {
  info: 'alertInfoFill',
  success: 'formCheckCircle',
  warning: 'alertCircleColor',
  error: 'formXCircle',
};

export type Status = 'info' | 'success' | 'warning' | 'error';

export type Variant = 'alert' | 'banner' | 'compact' | 'toast';

export type Props = {
  /**
   * Controls the `aria-live` attribute of `Alert`.
   */
  'aria-live'?: 'off' | 'polite' | 'assertive';
  /**
   * The content to render within `Alert`. Typically comprised of
   * subcomponents like `AlertDescription` and `ButtonGroup`.
   */
  children?: React.ReactNode;
  /**
   * Optional function to call when `Alert` is dismissed.
   * Its presence determines whether the close button renders.
   */
  onDismiss?: (e: React.MouseEvent) => void;
  /**
   * Controls the `role` attribute of `Alert`.
   */
  role?: 'alert' | 'alertdialog';
  /**
   * Controls the styling of `Alert` to indicate to users what type
   * of alert it is.
   */
  status?: Status;
  /**
   * The title of the `Alert`.
   */
  title?: React.ReactNode;
  /**
   * Controls how content within `Alert` is laid out.
   */
  variant?: Variant;
};

/**
 * `Alert` renders information that is immediately or near-immediately
 * important for users to know.
 */
export const Alert = ({
  children = undefined,
  onDismiss = undefined,
  role = undefined,
  status = 'info',
  title = undefined,
  variant = 'alert',
  ...props
}: Props) => {
  const closeButton = onDismiss && (
    <button
      type="button"
      aria-label="Dismiss"
      className={styles.closeButton}
      onClick={onDismiss}
      data-zds
    >
      <Icon isBlock={true} name="formX" size={24} />
    </button>
  );

  return (
    <article
      aria-live={props['aria-live']}
      className={styles.root}
      data-status={status}
      role={role}
      data-variant={variant}
      data-zds
    >
      <div
        className={styles.statusIcon}
        data-status={status}
        data-variant={variant}
        data-zds
      >
        <Icon
          aria-label={status}
          isBlock={true}
          name={STATUS_ICONS[status] || 'alertInfoFill'}
          size={variant === 'compact' ? 20 : 24}
        />
      </div>
      <div className={styles.container} data-zds>
        {title && (
          <h2 className={styles.title} data-variant={variant} data-zds>
            {title}
          </h2>
        )}
        {children}
      </div>
      {closeButton}
    </article>
  );
};
