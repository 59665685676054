import React, { createContext, useContext, useState } from 'react';

type InteractiveTagContext = {
  body: string | undefined;
  isTruncated: boolean;
  setBody: (body: string | undefined) => void;
  setIsTruncated: (isTruncated: boolean) => void;
  setTitle: (body: string) => void;
  title: string | undefined;
};

export const InteractiveTagContext = createContext<
  InteractiveTagContext | undefined
>(undefined);

type InteractiveTagProviderProps = {
  children: React.ReactNode;
};

export const InteractiveTagProvider = ({
  children,
}: InteractiveTagProviderProps) => {
  const [body, setBody] = useState<string | undefined>(undefined);
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [isTruncated, setIsTruncated] = useState(false);

  return (
    <InteractiveTagContext.Provider
      value={{ body, isTruncated, setBody, setTitle, setIsTruncated, title }}
    >
      {children}
    </InteractiveTagContext.Provider>
  );
};

export const useInteractiveTagContext = () => {
  const context = useContext(InteractiveTagContext);
  if (context === undefined) {
    throw new Error(
      'useInteractiveTagContext must be used within an InteractiveTagProvider (which is available by using InteractiveTag or InteractiveTagWrapper)'
    );
  }
  return context;
};
