import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgStatusStopFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M19.075 4.933a10 10 0 0 0-14.15 0c-3.9 3.9-3.9 10.24 0 14.14a10 10 0 0 0 14.15 0c3.91-3.9 3.91-10.24 0-14.14ZM16 8H8v8h8V8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgStatusStopFill;
