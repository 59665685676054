export const ZAPIER_APP_BASE_URL = process.env.ZAPIER_APP_BASE_URL || '';

export const ZAP_EDITOR_URL =
  'https://docs.api.zapier.com/partner-solutions/workflow-api/embed-zap-editor';

// Zapier's main account ID is the same for staging and production
export const ZAPIER_ACCOUNT_ID = 2074697;

export const URLS = {
  docs: 'https://docs.zapier.com',
  // Monolith URLs.
  baseApp: `${ZAPIER_APP_BASE_URL}/app/developer`,
  buildIntegration: `${ZAPIER_APP_BASE_URL}/developer-platform/integrations`,
  developerPlatform: `${ZAPIER_APP_BASE_URL}/developer-platform`,
  legacyBuilderBase: `${ZAPIER_APP_BASE_URL}/developer/builder`,
  logOut: `${ZAPIER_APP_BASE_URL}/logout`,
  partnerProgram: `${ZAPIER_APP_BASE_URL}/developer-platform/partner-program`,
  platformRoot: `${ZAPIER_APP_BASE_URL}/platform`,
  platformTos: `${ZAPIER_APP_BASE_URL}/platform/tos`,
  privacyPolicy: `${ZAPIER_APP_BASE_URL}/privacy`,
  resources: `${ZAPIER_APP_BASE_URL}/developer-platform/resources`,
  tools: `${ZAPIER_APP_BASE_URL}/developer-platform/embed-tools`,
  userTos: `${ZAPIER_APP_BASE_URL}/terms`,
  zapier: `${ZAPIER_APP_BASE_URL}/`,
  // Platform URLs in the monollith.
  logIn: `${ZAPIER_APP_BASE_URL}/platform/login`,
  signUp: `${ZAPIER_APP_BASE_URL}/platform/sign-up`,
  // Visual Builder URLs.
  joinIntegration: `/join-integration`,
  settings: `/partner-settings/profile`,
  vbBase: `/`,
  zapTemplates: `/zap-templates`,
};
