/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { labelStyles } from '@zapier/style-encapsulation';

type Props = {
  /** The `Button`s to render within `ButtonGroup`. */
  children: React.ReactNode;
  /** Value to use within `grid-template-columns` `repeat` function. */
  columnSize?: string;
  /** How to horizontally justify the content of `ButtonGroup`. */
  justify?: 'start' | 'center' | 'end';
  /** How wide `ButtonGroup` can render within its container. */
  maxWidth?: string;
};

const Styles = labelStyles('ButtonGroup', {
  root: (maxWidth: Props['maxWidth']) => css`
    max-width: ${maxWidth};
  `,
  inner: (justify: Props['justify'], columnSize: Props['columnSize']) => css`
    display: grid;
    gap: var(--zds-space-12, 10px) var(--zds-space-20, 20px);
    justify-content: ${justify};
    grid-template-columns: repeat(auto-fit, ${columnSize});
  `,
});

/**
 * `ButtonGroup` renders a group of `Button` components that are equally spaced apart from each other.
 * By default, `Button`s will shrink and grow to distribute themselves across their container size.
 *
 * `ButtonGroup` is a simple wrapper that isn't intended to handle every use case.
 * If you need custom functionality, create your own wrapper around your group of `Button`s.
 */
export const ButtonGroup = ({
  children,
  columnSize = 'minmax(0, 1fr)',
  justify = 'start',
  maxWidth = 'initial',
}: Props) => {
  return (
    <div css={Styles.root(maxWidth)} data-zds>
      <div css={Styles.inner(justify, columnSize)} data-zds>
        {children}
      </div>
    </div>
  );
};
