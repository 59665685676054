import { isEmpty, mapValues } from 'lodash';
import { cleanRequest } from './cleanRequest';

import type { CreateActionSchema } from 'app/developer-v3/platformSchema/create-action';
import type { SearchActionSchema } from 'app/developer-v3/platformSchema/search-action';
import type { ExternalActionSchema } from 'app/developer-v3/platformSchema/external-action';

function cleanAction(
  action: CreateActionSchema | SearchActionSchema | ExternalActionSchema
) {
  const operation = 'operation' in action ? action.operation : undefined;
  const perform =
    operation && 'perform' in operation ? operation.perform : undefined;
  const hasPerform = !isEmpty(perform);

  return operation
    ? {
        ...action,
        operation: {
          ...operation,
          perform: hasPerform ? cleanRequest(perform) : {},
        },
      }
    : action;
}

function cleanActions(
  actions: Record<string, CreateActionSchema | SearchActionSchema>
) {
  return mapValues(actions, cleanAction);
}

export { cleanAction, cleanActions };
