/** @jsx jsx */

import { forwardRef } from 'react';
import { css, jsx } from '@emotion/react';
import { labelStyles } from '@zapier/style-encapsulation';
import { Icon, IconName } from '../../display/Icon';
import { Spinner } from '../../display/Spinner';
import { TextInput, type TextInputProps } from '../../forms/TextInput';
import { camelCaseAriaAttrs } from '../../../utils/formatAriaAttrKeys';
import { Animation, Colors } from '../../../theme';

import { GetInputPropsOptions } from 'downshift';

export type Props<T = any> = {
  /**
   * Browser `autocomplete` form field attribute.
   */
  autoComplete?: string;
  /**
   * Accessibility label that describes this component. This should be supplied
   * if no corresponding <label> element is used.
   */
  'aria-label'?: string;
  /**
   * Accessibility label referencing the ID of an element which describes this
   * component.
   */
  'aria-labelledby': string | null;
  /**
   * Callback provided by Downshift which clears the typeahead selection.
   */
  clearSelection: () => void;
  /**
   * The text that renders in a `Tooltip` when the input `isDisabled`.
   * Only renders when `isDisabled`.
   */
  disabledText?: string;
  /**
   * Callback provided by Downshift which provides props which must be spread
   * into the `input` element.
   */
  getInputProps: (options: GetInputPropsOptions) => GetInputPropsOptions;
  /**
   * An optional icon name to render on the right side of the input element.
   * Only visible when the X clear selection is not shown.
   */
  iconEndName?: IconName;
  /**
   * The current value of the input, provided by Downshift.
   */
  inputValue: string | null;
  /**
   * Displays an inline loading spinner in the place of the left icon if set to true.
   */
  isBusy?: boolean;
  /**
   * Disables the input and makes it non-interactive.
   */
  isDisabled?: boolean;
  /**
   * Indicates whether the field has an error.
   */
  isErrored?: boolean;
  /**
   * Callback that gets called when the input loses focus.
   */
  onBlur?: (event: React.SyntheticEvent) => void;
  /**
   * Optional callback that may be provided by Downshift to open the menu.
   */
  onFocus?: () => void;
  /**
   * Callback that gets called on the keydown event.
   */
  onKeyDown?: (event: React.SyntheticEvent) => void;
  /**
   * Placeholder text to render inside the `input` element toggle when no item
   * has been selected.
   */
  placeholder: string;
  /**
   * The currently selected item.
   */
  selectedItem: T | null;
  /**
   * Callback that will be used to generate the string label to be rendered for
   * the currently selected item. Provided by Downshift.
   */
  getLabelForItem: (item: T) => string;
  /**
   * A render prop which receives a menu `item` and the `getLabelForItem`
   * function and returns a React element, which is an icon illustrating the
   * given menu item. The natural size for the icon is 30x30px. If not
   * provided, no icons will be rendered.
   */
  renderIcon?: (
    item: T,
    getLabelForItem: (item: T) => string
  ) => React.ReactElement | null;
  /**
   * The rendered size of the input.
   */
  size?: TextInputProps['size'];
};

const Styles = labelStyles('TypeaheadInput', {
  clearSelectionButton: css`
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    &:hover,
    &:focus {
      color: ${Colors.GrayWarm6};
      transition: ${Animation.transitionValue};
    }
  `,
});

/**
 * The icon which sits inside the input field.
 */
const ClearSelectionButton = ({
  clearSelection,
}: {
  clearSelection: () => void;
  isFocused: boolean;
  isHovered: boolean;
}) => {
  return (
    <button
      type="button"
      css={Styles.clearSelectionButton}
      onClick={clearSelection}
      title="Clear Selection"
      data-zds
    >
      <Icon canAcceptPointerEvents={false} name="formXCircle" size={20} />
    </button>
  );
};

type InputIconProps = Props & {
  iconSize: number;
};
/**
 * The icon which sits inside the input field on the left side.
 */
const InputIcon = (props: InputIconProps) => {
  return props.renderIcon && props.selectedItem ? (
    props.renderIcon(props.selectedItem, props.getLabelForItem)
  ) : (
    // Search icon also handles the use case where an item has been
    // selected but props.renderIcon has not been specified.
    <Icon name="navSearch" size={props.iconSize} />
  );
};

export const TypeaheadInput = forwardRef<HTMLInputElement, Props>(
  ({ size = 'medium', ...props }, ref) => {
    const showClearSelectionButton = props.selectedItem && !props.isDisabled;

    return (
      <TextInput
        // TODO: figure out if camelCaseAriaAttrs can be removed. TextInput takes kebab-case aria attributes
        {...camelCaseAriaAttrs(
          props.getInputProps({
            // @ts-ignore this is valid, but downshift TS types are wrong
            ariaLabelledBy: props.ariaLabelledBy,
            autoComplete: props.autoComplete,
            onFocus: props.onFocus,
            onKeyDown: props.onKeyDown,
          })
        )}
        aria-label={props['aria-label']}
        autoComplete="off"
        canClickIconAfter={showClearSelectionButton}
        disabledText={props.disabledText}
        isDisabled={props.isDisabled}
        isErrored={props.isErrored}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        ref={ref}
        renderIconAfter={({ iconSize, isFocused, isHovered }) =>
          showClearSelectionButton ? (
            <ClearSelectionButton
              clearSelection={props.clearSelection}
              isFocused={isFocused}
              isHovered={isHovered}
            />
          ) : (
            props.iconEndName && (
              <Icon name={props.iconEndName} size={iconSize} />
            )
          )
        }
        renderIconBefore={({ iconSize }: { iconSize: number }) => {
          if (props.isBusy) {
            return (
              <span style={{ width: iconSize }} data-zds>
                <Spinner />
              </span>
            );
          }
          return <InputIcon {...props} iconSize={iconSize} />;
        }}
        size={size}
      />
    );
  }
);
