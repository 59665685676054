/** @jsx jsx */

import { Colors, Link } from '@zapier/design-system';
import { css, jsx } from '@emotion/core';

type SkipLinkProps = {
  href?: string;
  text?: string;
};

const Styles = {
  root: css`
    color: ${Colors.GrayWarm9} !important;
    display: block !important;
    fontSize: 10px !important;
    textTransform: uppercase !important;
    /* Hide the link and make it unclickable. */
    opacity: 0 !important;
    pointerEvents: none !important;
    textDecoration: none !important;
    width: 0px !important;
    /* Only show the link on focus. */
    &:focus {
      opacity: 1 !important;
      outline: none !important;
    },
  `,
};

const defaultProps = {
  href: '#mainContent',
  text: 'Skip to content',
};

function SkipLink({
  href = defaultProps.href,
  text = defaultProps.text,
}: SkipLinkProps) {
  return (
    <Link
      // Without the `main`, screen reader will read this as `Skip to conTENT`
      aria-label="Skip to main content"
      css={Styles.root}
      href={href}
    >
      {text}
    </Link>
  );
}

SkipLink.defaultProps = defaultProps;

export default SkipLink;
