import React from 'react';

// This hash is substituted in the "build all" step in .gitlab-ci.yml
const VERSION = 'b9e7c59b';
export const ZINNIA_BETA_STYLESHEET_URL = `https://design-system-css.zapier.com/design-system-beta/${VERSION}.css`;

export const ZinniaBetaStylesheet = (
  props: React.HTMLAttributes<HTMLLinkElement>
) => {
  return <link href={ZINNIA_BETA_STYLESHEET_URL} rel="stylesheet" {...props} />;
};
