import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgStatusStop = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M19.07 4.93a10 10 0 0 0-14.15 0c-3.9 3.9-3.9 10.24 0 14.14a10 10 0 0 0 14.15 0c3.91-3.9 3.91-10.24 0-14.14Zm-1.41 12.73c-3.13 3.12-8.19 3.13-11.32 0-3.12-3.12-3.12-8.19 0-11.31 3.13-3.12 8.19-3.13 11.32 0 3.12 3.11 3.12 8.19 0 11.31Z"
    />
    <path fill="#2D2E2E" d="M8 8h8v8H8V8Z" />
  </svg>
);
export default SvgStatusStop;
