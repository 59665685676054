import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionEditAi = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M20.43 8.52 16.92 5 5 16.91V19h2.09L17.6 8.48l1.42 1.42L7.91 21H3v-4.91L16.92 2.17l4.93 4.93-1.42 1.42ZM18.483 14l1.28 2.202 2.203 1.281-2.202 1.28-1.28 2.203-1.282-2.202L15 17.484l2.202-1.282L18.483 14Z"
    />
  </svg>
);
export default SvgActionEditAi;
