/** @jsx jsx */
import { jsx } from '@emotion/react';
import { forwardRef } from 'react';
import type { Ref } from 'react';

import { Typography } from '@/theme';
import { Input } from '../../forms/Input';
import type { InputProps } from '../../forms/Input';
import { labelStyles, withModifier } from '@zapier/style-encapsulation';

type TextInputRef = HTMLInputElement | HTMLTextAreaElement;

export type Props = Omit<
  InputProps<HTMLInputElement & HTMLTextAreaElement>,
  'children'
> & {
  /**
   * Controls how text is automatically capitalized as it's entered by the user.
   */
  autoCapitalize?: 'on' | 'off' | 'words' | 'characters';
  /**
   * Browser `autocomplete` form field attribute.
   */
  autoComplete?: string;
  /**
   * Activates automatic correct while the user is editing the field (used by Safari).
   */
  autoCorrect?: 'on' | 'off';
  /**
   * This prop is only intended to be overriden when necessary.
   */
  borderRadius?: 'none' | 'small' | 'medium' | 'large';
  /** The initial value of an uncontrolled `TextInput` */
  defaultValue?: string;
  /**
   * Should this component render in its full width?
   */
  isFullWidth?: boolean;
  /**
   * Whether the input accepts multiple lines of text.
   * Renders an `input` if `false` and a `textarea` if true`.
   */
  isMultiline?: boolean;
  /**
   * HTML inputmode for controlling which keyboard shows on mobile:
   * https://css-tricks.com/everything-you-ever-wanted-to-know-about-inputmode/
   * https://html.spec.whatwg.org/multipage/interaction.html#input-modalities:-the-inputmode-attribute
   */
  inputMode?:
    | 'decimal'
    | 'email'
    | 'none'
    | 'numeric'
    | 'search'
    | 'tel'
    | 'text'
    | 'url';
  /**
   * Limits the maximum number of characters within the input.
   */
  maxLength?: number;
  /**
   * Indicates in which directions a `textarea` can be resized.
   * Only works when `isMultiline={true}`!
   */
  resize?: 'horizontal' | 'vertical' | 'both' | 'none';
  /**
   * Indicates how many rows should render by default for a `textarea`s.
   * Only works when `isMultiline={true}`!
   */
  rows?: number;
  /** Use spelling and grammar checks in the text input. */
  spellCheck?: 'true' | 'false';
  /**
   * Indicates the type of the input so the browser can provide a better experience to users.
   * Only used for `input` fields and *not* `textarea`s, so is affected by `isMultiline`.
   */
  type?: React.HTMLInputTypeAttribute;
};

const Styles = labelStyles('TextInput', {
  input: (props: Props) => [
    // FireFox puts an arbitrary min-width on the input otherwise.
    { minWidth: 1 },
    props.isMultiline &&
      withModifier('multiline', {
        height: 'auto',
      }),

    props.isFullWidth &&
      withModifier('fullwidth', {
        width: '100%',
      }),

    props.resize &&
      withModifier(`resize-${props.resize}`, {
        resize: props.resize,
      }),

    props.size === 'compact' &&
      withModifier(`size-${props.size}`, {
        ...Typography.MinimalPrint2,
      }),
  ],
});

/**
 * Renders either an `input` or `textarea` element, depending on the `isMultiline` prop.
 * This is a bare-bones implementation without any special handling for events.
 */
export const TextInput = forwardRef<TextInputRef, Props>(
  (
    {
      defaultValue,
      isMultiline = false,
      type = 'text',
      isFullWidth = false,
      size = 'medium',
      borderRadius = 'medium',
      ...otherProps
    },
    ref
  ) => {
    const props = {
      isMultiline,
      type,
      isFullWidth,
      size,
      borderRadius,
      ...otherProps,
    };
    const Tag = props.isMultiline ? 'textarea' : 'input';
    return (
      <Input<HTMLInputElement & HTMLTextAreaElement>
        {...props}
        renderIconAfter={props.isMultiline ? undefined : props.renderIconAfter}
        renderIconBefore={
          props.isMultiline ? undefined : props.renderIconBefore
        }
      >
        {(inputProps) => (
          <Tag
            {...inputProps}
            autoCapitalize={props.autoCapitalize}
            autoCorrect={props.autoCorrect}
            css={[inputProps.css, Styles.input(props)]}
            defaultValue={defaultValue}
            inputMode={props.inputMode}
            ref={ref as Ref<any>}
            rows={props.isMultiline ? props.rows : undefined}
            spellCheck={props.spellCheck}
            type={!props.isMultiline ? props.type : undefined}
            value={inputProps.value}
            onChange={inputProps.onChange}
            data-zds
          />
        )}
      </Input>
    );
  }
);
