/** @jsx jsx */

import { css, jsx } from '@emotion/react';

import { Icon } from '../../display/Icon';
import type { IconName } from '../../display/Icon';

export type InteractiveTagIconProps = {
  /**
   * Acessible attribute to hide the icon from screenreaders when the icon is purely decorative.
   * Optional since parent node can accept click events and the icon can have a aria-label.
   */
  'aria-hidden'?: boolean;
  /**
   * Aria label to be displayed for accessibility.
   */
  'aria-label'?: string;
  /**
   * The name of the icon to be rendered.
   */
  name: IconName;
  /**
   * The position of the icon inside the tag. It determines spacing inside the tag.
   */
  position: 'before' | 'after';
};

const Styles = {
  root: (marginLeft: string, marginRight: string) =>
    css({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft,
      marginRight,
    }),
};

/**
 * Icon styled to be used inside of `InteractiveTag` or `InteractiveTag.Wrapper`.
 */
export const InteractiveTagIcon = ({
  'aria-hidden': ariaHidden = true,
  'aria-label': ariaLabel,
  name,
  position,
}: InteractiveTagIconProps) => {
  const calculateLeftMargin = () => {
    // Brings only the X icon closer to the left border.
    if (name === 'formX') {
      return position === 'before' ? '-5px' : '0px';
    }

    return '0px';
  };

  const calculateRightMargin = () => {
    // Brings only the X icon closer to the right border.
    if (name === 'formX') {
      return position === 'after' ? '-5px' : '0px';
    }

    return '0px';
  };

  return (
    <div
      css={Styles.root(calculateLeftMargin(), calculateRightMargin())}
      data-zds
    >
      <Icon
        aria-hidden={ariaHidden}
        aria-label={ariaLabel}
        name={name}
        size={24}
      />
    </div>
  );
};
