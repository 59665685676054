import { isEmpty, mapValues } from 'lodash';
import { cleanRequest } from './cleanRequest';

import type { ExternalTriggerSchema } from 'app/developer-v3/platformSchema/external-trigger';
import type { TriggerSchema } from 'app/developer-v3/platformSchema/trigger';

function cleanTrigger(trigger: TriggerSchema | ExternalTriggerSchema) {
  const operation = 'operation' in trigger ? trigger.operation : undefined;
  const perform =
    operation && 'perform' in operation ? operation.perform : undefined;
  const performList =
    operation && 'performList' in operation ? operation.performList : undefined;
  const performSubscribe =
    operation && 'performSubscribe' in operation
      ? operation.performSubscribe
      : undefined;
  const performUnsubscribe =
    operation && 'performUnsubscribe' in operation
      ? operation.performUnsubscribe
      : undefined;

  const hasPerform = !isEmpty(perform);
  const hasPerformList = !isEmpty(performList);
  const hasPerformSubscribe = !isEmpty(performSubscribe);
  const hasPerformUnsubscribe = !isEmpty(performUnsubscribe);

  return operation
    ? {
        ...trigger,
        operation: {
          ...operation,
          ...(hasPerform ? { perform: cleanRequest(perform) } : {}),
          ...(hasPerformList ? { performList: cleanRequest(performList) } : {}),
          ...(hasPerformSubscribe
            ? { performSubscribe: cleanRequest(performSubscribe) }
            : {}),
          ...(hasPerformUnsubscribe
            ? { performUnsubscribe: cleanRequest(performUnsubscribe) }
            : {}),
        },
      }
    : trigger;
}

function cleanTriggers(triggers: Record<string, TriggerSchema>) {
  return mapValues(triggers, cleanTrigger);
}

export { cleanTrigger, cleanTriggers };
