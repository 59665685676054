/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { withModifier, labelStyles } from '@zapier/style-encapsulation';
import { Icon } from '../../display/Icon';
import type { IconName } from '../../display/Icon';
import { Animation, Colors } from '../../../theme';

export type BooleanInputType = 'radio' | 'checkbox';
export type Props = {
  /**
   * Optional accessibility label for the underlying `input`.
   * Using a `label` tag is generally preferred for more clickable area.
   */
  'aria-label'?: string;

  /**
   * Indicates whether `BooleanInput` is checked.
   */
  checked?: boolean;
  /**
   * Indicates whether `BooleanInput` is disabled.
   */
  disabled?: boolean;
  /**
   * `id` that is attached to the underlying `input`, used to attach
   * an external `label` to it via the `for` attribute.
   */
  id?: string;
  /**
   * Indicates whether `BooleanInput` is errored.
   */
  isErrored?: boolean;
  /**
   * Indicates whether the `input` is required.
   * For checkboxes, this means the checkbox itself must be checked.
   * For radios, this means one of the radios with the same `name`
   * must be checked.
   */
  isRequired?: boolean;
  /**
   * `name` that is attached to the underlying `input`, used to group
   * multiple `BooleanInput` values together.
   */
  name?: string;
  /**
   * Handler for when the `input` changes between checked and unchecked.
   */
  onChange?: (e: React.SyntheticEvent) => void;
  /**
   * The `tabIndex`. The default is almost always correct unless this
   * `BooleanInput` is being used inside a larger control that manages
   * focus state and keyboard interactions. e.g. a dropdown.
   */
  tabIndex?: number;
  /**
   * The type of `input` to render.
   */
  type: BooleanInputType;
  /**
   * `value` that is attached to the underlying `input`, used to differentiate
   * one `input` from another.
   */
  value?: string;
};
const borderWidth = 2;
const Styles = labelStyles('BooleanInput', {
  root: () => [
    css({
      position: 'relative',
      display: 'inline-block',
    }),
  ],
  inner: (props: Props) => [
    css({
      display: 'block',
      position: 'relative',
      zIndex: 1,
      border: `${borderWidth}px solid ${Colors.GrayWarm6}`,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      transition: `all ${Animation.transitionDuration} ease-in-out`,
    }),
    props.type === 'checkbox' && [
      withModifier(
        'checkbox',
        css({
          width: 18,
          height: 18,
          borderRadius: 'var(--zds-radius-none)',
          color: Colors.PrimeWhite,
        })
      ),
      props.checked &&
        withModifier(
          'checked',
          css({
            backgroundColor: `var(--zds-ui-primary-stronger)`,
            borderColor: 'transparent',
          })
        ),
      props.isErrored &&
        withModifier(
          'errored',
          css({
            backgroundColor: props.checked
              ? `var(--zds-status-error)`
              : 'transparent',
            borderColor: `var(--zds-status-error)`,
          })
        ),
      props.disabled &&
        withModifier(
          'disabled',
          css({
            backgroundColor: props.checked ? Colors.GrayWarm5 : 'transparent',
            borderColor: Colors.GrayWarm5,
            cursor: 'not-allowed',
          })
        ),
    ],
    props.type === 'radio' && [
      withModifier(
        'radio',
        css({
          color: 'transparent',
          width: 20,
          height: 20,
          borderRadius: 20,
        })
      ),
      props.checked &&
        withModifier(
          'checked',
          css({
            borderColor: `var(--zds-ui-primary-stronger)`,
            color: `var(--zds-ui-primary-stronger)`,
          })
        ),
      props.isErrored &&
        withModifier(
          'errored',
          css({
            borderColor: `var(--zds-status-error)`,
            color: `var(--zds-status-error)`,
          })
        ),
      props.disabled &&
        withModifier(
          'disabled',
          css({
            borderColor: Colors.GrayWarm5,
            color: Colors.GrayWarm5,
            cursor: 'not-allowed',
          })
        ),
    ],
  ],
  layer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: -borderWidth,
    left: -borderWidth,
    width: `calc(100% + ${2 * borderWidth}px)`,
    height: `calc(100% + ${2 * borderWidth}px)`,
  }),
});

/**
 * `BooleanInput` is a stylized `input[type="checkbox | radio"]`.
 */
export const BooleanInput = ({
  type,
  'aria-label': ariaLabel = undefined,
  checked = false,
  disabled = undefined,
  id = undefined,
  isErrored = undefined,
  isRequired = undefined,
  name = undefined,
  onChange = () => {},
  tabIndex = 0,
  value = undefined,
}: Props) => {
  const props = {
    type,
    'aria-label': ariaLabel,
    checked,
    disabled,
    id,
    isErrored,
    isRequired,
    name,
    onChange,
    tabIndex,
    value,
  } satisfies Props;

  return (
    <span css={Styles.root()} data-zds>
      <span css={Styles.inner(props)} data-zds>
        <input
          aria-label={ariaLabel}
          checked={checked}
          css={Styles.layer}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          required={isRequired}
          tabIndex={tabIndex}
          type={type}
          value={value}
          data-zds
        />
        {checked && (
          <span css={Styles.layer} data-zds>
            <Icon
              isBlock={true}
              name={
                {
                  checkbox: 'formCheck',
                  radio: 'formRadioDot',
                }[type] as IconName
              }
              size={18}
            />
          </span>
        )}
      </span>
    </span>
  );
};
