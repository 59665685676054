import semverCompare from 'semver-compare';

function sortVersionStrings(versions: Array<string>): Array<string> {
  return [...versions].sort(semverCompare).reverse();
}

function sortVersionObjects<T extends { version: string }>(versions: T[]) {
  const copiedVersions = [...versions]
    .sort((objA, objB) => semverCompare(objA.version, objB.version))
    .reverse();
  return copiedVersions;
}

function findNextPatchVersion(
  version: string,
  existingVersions: string[] = []
) {
  const versionParts = version.split('.').map(i => parseInt(i, 10));

  let nextPatchVersion;
  let bump = 0;
  do {
    bump = bump + 1;
    nextPatchVersion = [
      versionParts[0],
      versionParts[1],
      versionParts[2] + bump,
    ].join('.');
  } while (existingVersions.includes(nextPatchVersion));

  return nextPatchVersion;
}

function findNextMinorVersion(
  version: string,
  existingVersions: string[] = []
) {
  const versionParts = version.split('.').map(i => parseInt(i, 10));

  let nextPatchVersion;
  let bump = 0;
  do {
    bump = bump + 1;
    nextPatchVersion = [versionParts[0], versionParts[1] + bump, 0].join('.');
  } while (existingVersions.includes(nextPatchVersion));

  return nextPatchVersion;
}

function findNextMajorVersion(
  version: string,
  existingVersions: string[] = []
) {
  const versionParts = version.split('.').map(i => parseInt(i, 10));

  let nextPatchVersion;
  let bump = 0;
  do {
    bump = bump + 1;
    nextPatchVersion = [versionParts[0] + bump, 0, 0].join('.');
  } while (existingVersions.includes(nextPatchVersion));

  return nextPatchVersion;
}

function incrementVersion(
  version: string, // must be in the form a.b.c
  existingVersions: string[] = []
): Array<{ value: string; label: string }> {
  const nextPatchVersion = findNextPatchVersion(version, existingVersions);
  const nextMinorVersion = findNextMinorVersion(version, existingVersions);
  const nextMajorVersion = findNextMajorVersion(version, existingVersions);

  return [
    {
      value: nextPatchVersion,
      label: `${nextPatchVersion} (Patch)`,
    },
    {
      value: nextMinorVersion,
      label: `${nextMinorVersion} (Minor)`,
    },
    {
      value: nextMajorVersion,
      label: `${nextMajorVersion} (Major)`,
    },
  ];
}

export { incrementVersion, sortVersionObjects, sortVersionStrings };
