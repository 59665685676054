import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscClockPlay = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M4 12a8 8 0 0 1 8-8 8 8 0 0 1 7.93 7h2a10 10 0 1 0-9.95 11h1v-2a8.238 8.238 0 0 1-1 .07A7.998 7.998 0 0 1 4 12Zm7-.46-3.13 2.7 1.31 1.52 3.82-3.3V7h-2v4.54Z"
    />
    <path fill="#2D2E2E" d="m16 13 7 4.5-7 4.5v-9Z" />
  </svg>
);
export default SvgMiscClockPlay;
