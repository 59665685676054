/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Icon, IconName } from '../../display/Icon';
import { TooltipPosition } from '../../display/Tooltip';
import { TooltipWrapper } from '../../display/TooltipWrapper';
import {
  BaseButton,
  Props as BaseButtonProps,
} from '../../navigation/Button/BaseButton';

export type Props = Omit<
  BaseButtonProps,
  'children' | 'iconBefore' | 'isFullWidth' | 'isPill' | 'isSquare'
> & {
  color?:
    | 'icon-alt'
    | 'icon-ghost'
    | 'icon-primary'
    | 'icon-primary-dark'
    | 'icon-secondary'
    | 'icon-secondary-dark'
    | 'icon-tertiary';
  /**
   * Name of the `Icon` to render.
   */
  icon: IconName;
  /**
   * Indicates what the button does. Used for the `aria-label` on the
   * `Button` as well as the text for the `Tooltip`.
   * This prop should be supplied in most circumstances, but in cases
   * **when it's not desired be sure to pass `aria-label`!**
   */
  label?: string;
  /** Indicates whether to flip the `Tooltip` based on available space. */
  shouldCorrectTooltipPosition?: boolean;
  /**
   * Optional prop to associate the tooltip and the button that controls it.
   */
  tooltipId?: string;
  /**
   * Where to render the `Tooltip` relative to the button.
   */
  tooltipPosition?: TooltipPosition;
  /**
   * Renders `IconButton` in a circle shape.
   */
  isCircle?: boolean;
};

/**
 * One IconButton to rule them all
 * Used by IconButton and DarkBackgroundIconButton
 */

export function BaseIconButton({
  icon,
  isCircle,
  label,
  shouldCorrectTooltipPosition,
  tooltipId,
  tooltipPosition,
  ...buttonProps
}: Props) {
  return (
    <TooltipWrapper
      content={label}
      position={tooltipPosition}
      shouldCorrectPosition={shouldCorrectTooltipPosition}
      showTooltipOnTouchDevices={false}
      tooltipId={tooltipId}
    >
      {({ childProps }) => (
        <BaseButton
          aria-label={label}
          {...buttonProps}
          {...childProps}
          isPill={isCircle}
          isSquare={true}
        >
          <Icon canAcceptPointerEvents={false} isBlock={true} name={icon} />
        </BaseButton>
      )}
    </TooltipWrapper>
  );
}
