/** @jsx jsx */

import { css, jsx } from '@emotion/react';

import { labelStyles } from '@zapier/style-encapsulation';
import { Shadows, Typography } from '../../../theme';
import { createUniqueCustomProperties } from '../../../utils/createUniqueCustomProperty';

type Props = {
  /**
   * The content to render. Be sure to handle scrolling if your content
   * won't fit on small screens.
   */
  children: React.ReactNode;
  /**
   * The rendered close button provided by `Modal`.
   */
  closeButton?: React.ReactNode;
  /**
   * The maximum width of `ModalContent`. Any valid CSS value can be used.
   * Only strings are supported to avoid special handling for numbers, so
   * make sure to provide a unit such as `px`.
   */
  maxWidth?: string;
};

const Vars = createUniqueCustomProperties('ModalMediaContent', [
  'spacing',
  'footerMarginBottom',
]);

const Styles = labelStyles('ModalContent', {
  root: css`
    ${Vars.spacing}: var(--zds-space-20);
    ${Vars.footerMarginBottom}: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 70px);
    background-color: var(--zds-background-weaker);
    padding: var(${Vars.spacing});
    // 40px handles bottom toolbar in iOS Safari
    margin: var(--zds-space-12) var(--zds-space-12)
      calc(var(--zds-space-12) + 40px);
    border-radius: var(--zds-radius-medium);
    border: var(--zds-border-default);
    box-shadow: ${Shadows.elevation5};

    @media screen and (min-width: 650px) {
      // Effectively tighten the bottom padding of ModalContent
      // by pulling it up via margin.
      ${Vars.footerMarginBottom}: -10px;
      min-width: 550px;
      max-width: 970px;
      max-height: 650px;
      padding: calc(var(${Vars.spacing}) * 2);
      margin: var(--zds-space-32);
    }
  `,
  closeButton: css`
    position: absolute;
    top: var(--zds-space-8);
    right: var(--zds-space-8);
  `,
  modalContentHeader: css`
    ${Typography.ParagraphHeader4};
    margin-bottom: var(${Vars.spacing});
    color: var(--zds-text-stronger);
    // Protect header from close button.
    padding-right: var(--zds-space-20);
  `,
  modalContentBody: css`
    color: var(--zds-text-default);
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    // Support scrolling the child.
    min-height: 0;

    &[data-size='small'] {
      ${Typography.Body1};
      color: var(--zds-text-default);
    }

    &[data-size='medium'] {
      ${Typography.Body1};
    }

    &[data-scrollable] {
      // This is the gradient fader that renders over content
      // to help indicate that it's scrollable.
      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: var(--zds-space-48);
        pointer-events: none;
        background: linear-gradient(
          0deg,
          var(--zds-background-weaker) 0%,
          #fff0 75%
        );
      }
    }
  `,
  modalContentBodyInner: css`
    flex: 1;

    &[data-scrollable] {
      overflow: auto;
      // Add padding so end content isn't covered by the gradient fader.
      padding-bottom: var(--zds-space-28);

      // force showing the scrollbar always
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: var(--zds-radius-small);
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
  `,
  modalContentFooter: css`
    display: flex;
    flex-wrap: wrap;
    gap: var(--zds-space-12) var(--zds-space-20);
    justify-content: right;
    margin: var(${Vars.spacing}) 0 var(${Vars.footerMarginBottom}) auto;

    // Always a little 🤢 to use these selectors, but it helps to ensure
    // that they stretch full width on small screens. The attribute qualifiers
    // help with specificity.
    > *[data-zds],
    > *[style] {
      flex: 1 1 auto;
    }
  `,
});

export const ModalContentHeader = (props: { children: React.ReactNode }) => {
  return (
    <header css={Styles.modalContentHeader} data-zds>
      {props.children}
    </header>
  );
};

export const ModalContentBody = (props: {
  size?: 'small' | 'medium';
  children: React.ReactNode;
  scrollable?: boolean;
}) => {
  const { size = props.scrollable ? 'small' : 'medium' } = props;
  return (
    <div
      css={Styles.modalContentBody}
      data-scrollable={props.scrollable === true ? '' : undefined}
      data-size={size}
      data-zds
    >
      <div
        css={Styles.modalContentBodyInner}
        data-scrollable={props.scrollable === true ? '' : undefined}
        tabIndex={props.scrollable === true ? 0 : undefined}
        data-zds
      >
        {props.children}
      </div>
    </div>
  );
};

export const ModalContentFooter = (props: { children: React.ReactNode }) => {
  return <footer css={Styles.modalContentFooter}>{props.children}</footer>;
};

export const ModalContent = (props: Props) => {
  const style = {
    maxWidth: props.maxWidth,
  };
  return (
    <div css={Styles.root} style={style} data-zds>
      {props.children}
      {props.closeButton && (
        <div css={Styles.closeButton}>{props.closeButton}</div>
      )}
    </div>
  );
};
