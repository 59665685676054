import queryString from 'query-string';
import { camelCase, get, has, isEmpty } from 'lodash';

import { Colors } from '@zapier/design-system';

import { getAppVersionUrl } from 'app/developer-v3/utils';
import {
  highlightActionApiKeys,
  highlightAuthFieldKey,
  highlightTriggerApiKeys,
} from 'app/developer-v3/constants';

import type {
  DeepLinkParams,
  QueryHighlight,
  ViolationTypes,
} from 'app/developer-v3/types';

/**
 * Builds the query parameters for the deeplink
 * @param {*} deepLink
 * @param {*} params
 */
const mapObjectToParameters = (deepLink: string, params: DeepLinkParams) => {
  const symbol = !isEmpty(params)
    ? deepLink.indexOf('?') !== -1
      ? '&'
      : '?'
    : '';

  return `${deepLink}${symbol}${queryString.stringify(params)}`;
};

/**
 * Builds the url to link a user to a GUI integration page where
 * the violation happened.
 *
 * The deepLink is a string field that can be append to
 * the base URL BASE_APP_URL/app/:app_id/version/:version
 *
 * When deepLink is not provided, returns null
 *
 * Usage:
 * ```
 *   getDeepLinkUrl(1, '1.0.0', 'triggers/thing/input', {});
 *
 *   // returns: BASE_APP_URL/app/1/version/1.0.0/triggers/thing/input
 *
 * ```
 *
 * @param {string|number} appId
 * @param {string} appVersionNumber
 * @param {string} deepLink
 * @returns {string|null}
 */
const getDeepLinkUrl = (
  appId: number | string,
  appVersionNumber: string,
  deepLink: string,
  params: DeepLinkParams
) => {
  return `${getAppVersionUrl(appId, appVersionNumber)}/${mapObjectToParameters(
    deepLink,
    params
  )}`;
};

/**
 * Returns the step that matches the given highlight key
 *
 * Query can either be:
 * highlight=inputFields.{index}' or highlight={highlight}',
 *
 * @param {object} stepsMap
 * @param {string} highlightKey
 * @param {number} defaultStep
 * @returns {number}
 */
const getQueryHighlightStep = (
  stepsMap: Object = {},
  highlightKey: string = '',
  defaultStep: number = 1
) => get(stepsMap, highlightKey.split('.')[0], defaultStep);

/**
 * Returns the step that matches the given highlight key for the
 * Authentication step.
 *
 * The highlight query parameter for authentication may include the type
 * of auth. In order to open the accordions, we need to trim the first part
 * of the query.
 *
 * @param {string} highlightKey query parameter
 * @param {string} type authType
 * @param {number} defaultStep default step to open, usually the first
 * @returns {number} the number of the step to open
 */
const getAuthenticationQueryHighlight = (
  highlightKey: string = '',
  type: string = '',
  defaultStep: number = 1
) => {
  const splitQuery = highlightKey.split('.');

  const mapTypeToQuery = {
    basic: 'basicConfig',
    session: 'sessionConfig',
    custom: 'customConfig',
    oauth2: 'oauth2Config',
    digest: 'digestConfig',
  };

  const steps = {
    // https://github.com/zapier/zapier-platform/blob/main/packages/schema/docs/build/schema.md#authenticationbasicconfigschema
    basicConfig: {
      connectionLabel: 1,
      perform: 1,
      test: 2,
    },
    // https://github.com/zapier/zapier-platform/blob/main/packages/schema/docs/build/schema.md#authenticationoauth1configschema
    oauth2Config: {
      fields: 1,
      redirectUri: 2,
      credentials: 3,
      connectionLabel: 4,
      authorizeUrl: 4,
      getAccessToken: 4,
      refreshAccessToken: 4,
      scope: 4,
      autoRefresh: 4,
      test: 5,
    },
    // https://github.com/zapier/zapier-platform/blob/main/packages/schema/docs/build/schema.md#authenticationsessionconfigschema
    sessionConfig: {
      fields: 1,
      perform: 1,
      test: 4,
    },
    // https://github.com/zapier/zapier-platform/blob/main/packages/schema/docs/build/schema.md#authenticationcustomconfigschema
    customConfig: {
      fields: 1,
      connectionLabel: 2,
      perform: 2,
      test: 3,
    },
    //https://github.com/zapier/zapier-platform/blob/main/packages/schema/docs/build/schema.md#authenticationdigestconfigschema
    digestConfig: {
      fields: 1,
      perform: 2,
      test: 3,
    },
  };

  // check if first part matches the type of auth
  if (get(steps, splitQuery[0], null)) {
    return get(steps, [splitQuery[0], splitQuery[1]], defaultStep);
  } else {
    return get(steps, [mapTypeToQuery[type], splitQuery[0]], defaultStep);
  }
};

/**
 * Used for formatic fields.
 * It builds an object based on the query provided than can
 * be consumed as `meta`
 *
 * @param {QueryHighlight} query
 */
const getDeepLinkMetaInfoFromQuery = (query: QueryHighlight = {}) =>
  !isEmpty(query) && query.highlight
    ? {
        [query.highlight]: {
          message: query.message,
          type: query.type,
        },
      }
    : query;

/**
 * Direct Link border color depends on the type of validation error
 *
 * @param {ViolationTypes} type
 */
const getBorderColor = (type: ViolationTypes) => {
  return type
    ? {
        errors: Colors.Red4,
        warnings: Colors.Blue3,
        activationTasks: Colors.Blue3,
      }[type]
    : Colors.GrayWarm4;
};

/**
 * Direct Link border color depends on the type of validation error
 * TODO: This is a middle step in order to update everything into Zinnia
 *
 * @param {ViolationTypes} type
 */
const getBorderColorName = (type: ViolationTypes) => {
  return type
    ? {
        errors: 'error',
        warnings: 'UiPrimaryStrongest',
        activationTasks: 'UiPrimaryStrongest',
      }[type]
    : 'GrayWarm4';
};

/**
 * Some fields are provided from the backend with a diffrent name:
 * - label instead of noun in action and triggers settings
 *
 * @param {QueryHighlight} query
 * @param {string} highlightKey
 * @param {string} newName
 * @returns {QueryHighlight} query
 */
const mapQueryToName = (
  query: QueryHighlight,
  highlightKey: string,
  newName: string
) => {
  return query?.highlight === highlightKey
    ? { ...query, highlight: newName }
    : query;
};

/**
 * For Actions API not formatic fields returns the first part
 * of the query provided
 *
 * @param {String} highlight
 * @returns {String}
 */
const getHighlightActionApiFieldKey = (highlightKey: string) =>
  Object.keys(highlightActionApiKeys).find(key =>
    highlightKey.startsWith(key) ? key : ''
  );

/**
 * For Triggers API not formatic fields returns the first part
 * of the query provided
 *
 * @param {String} highlight
 * @returns {String}
 */
const getHighlightTriggerApiFieldKey = (highlightKey: string) =>
  Object.keys(highlightTriggerApiKeys).find(key =>
    highlightKey.startsWith(key) ? key : ''
  );

/**
 * For Authentication fields that aren't formatic
 * @param {*} highlight
 */
const getHighlightAuthenticationFieldKey = (highlight: string) => {
  return highlight.includes(highlightAuthFieldKey.connectionLabel)
    ? highlightAuthFieldKey.connectionLabel
    : highlight.includes(highlightAuthFieldKey.test)
    ? highlightAuthFieldKey.test
    : '';
};

const convertDeepLinkMetaKeyToCamelCase = (meta: {}, key: string) =>
  has(meta, key) ? { [camelCase(key)]: { ...meta[key] } } : meta;

export {
  convertDeepLinkMetaKeyToCamelCase,
  getAuthenticationQueryHighlight,
  getBorderColor,
  getBorderColorName,
  getDeepLinkUrl,
  getDeepLinkMetaInfoFromQuery,
  getQueryHighlightStep,
  getHighlightAuthenticationFieldKey,
  getHighlightActionApiFieldKey,
  getHighlightTriggerApiFieldKey,
  mapObjectToParameters,
  mapQueryToName,
};
