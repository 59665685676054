import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgArrowResizeHorizontal = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m20.31 12-4.2-5H13.5l4.19 5-4.19 5h2.61l4.2-5ZM10.5 7H7.89l-4.2 5 4.2 5h2.61l-4.19-5 4.19-5Z"
    />
  </svg>
);
export default SvgArrowResizeHorizontal;
