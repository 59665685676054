import React, { ComponentProps } from 'react';

import { Button, Spinner } from '@zapier/design-system';

type Props = ComponentProps<typeof Button> & {
  /** Flag to render the Spinner icon */
  isWorking: boolean;
  /** Text label for the button or any custom Node, including functions. */
  children: React.ReactNode;
};

const AsyncButton = ({ children, isWorking, ...buttonProps }: Props) => {
  const content = isWorking ? (
    <Spinner aria-label="Content is loading" />
  ) : (
    children
  );

  return <Button {...buttonProps}>{content}</Button>;
};

export default AsyncButton;
