/** @jsx jsx */
import { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';

import { Img } from '../../display/Img';
import { Animation } from '../../../theme';

export type Props = {
  /**
   * Controls whether `Avatar` is hidden from screen readers.
   * Useful when it renders alongside redundant content.
   */
  'aria-hidden'?: boolean;

  /**
   * Background color of the Avatar when no image exists.
   * When omitted, will default to `primary`, `secondary`, or `tertiary` based on the child number it is.
   */
  color?: 'primary' | 'secondary' | 'tertiary';

  /** Indicates whether to render the component as a block-level element. */
  isBlock?: boolean;

  /** Indicates whether the Avatar is interactive, i.e. that it lives within an interactive node. */
  isInteractive?: boolean;

  /** Indicates whether the Avatar is selected / clicked on. */
  isSelected?: boolean;

  /** Name of user */
  name: string;

  /** Whether or not to lazy load the underlying image. */
  shouldLazilyLoad?: boolean;

  /** Size of the `Avatar` to display.
   * @default 'small'
   */
  size?: 'small' | 'medium' | 'large';

  /** URL to image */
  url?: string | null;
};

const getNameInitials = (name: string): string => {
  const initials = (name || '')
    .trim()
    .split(/\s+/)
    .map((s) => s[0]);
  const firstLetter = initials[0];
  const lastLetter = initials[initials.length - 1];

  if (!firstLetter) {
    return '';
  }

  return initials.length === 1
    ? String(firstLetter)
    : `${firstLetter}${lastLetter}`;
};

const SIZES = {
  small: 24,
  medium: 36,
  large: 48,
};

const Styles = {
  root: (hasError: boolean, props: Pick<Props, 'url'>) => [
    css`
      position: relative;
      overflow: hidden;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: var(--zds-border-width-default) solid
        var(--zds-brand-almost-white);
      border-radius: var(--zds-radius-pill);
      line-height: 1;
      user-select: none;
      transition: ${Animation.transitionValue};

      &[data-block] {
        display: flex;
      }

      &[data-interactive] {
        &:hover,
        &:focus {
          border-color: var(--zds-stroke-weaker);
        }
      }

      &[data-selected][data-selected] {
        border: none;
        outline: var(--zds-border-focus);
        outline-offset: 2px;
      }
    `,
    !props.url || (props.url && hasError)
      ? css`
          &:nth-of-type(3n + 1),
          &[data-color='primary'][data-color] {
            background-color: var(--zds-color-blue-2);
            color: var(--zds-text-stronger);
          }

          &:nth-of-type(3n + 2),
          &[data-color='secondary'][data-color] {
            background-color: var(--zds-color-teal-2);
            color: var(--zds-text-stronger);
          }

          &:nth-of-type(3n),
          &[data-color='tertiary'][data-color] {
            background-color: var(--zds-color-pink-2);
            color: var(--zds-text-stronger);
          }
        `
      : null,
    css`
      &[data-size='small'] {
        height: var(--zds-size-small);
        width: var(--zds-size-small);
        font: var(--zds-minimal-print-3);
      }

      &[data-size='medium'] {
        height: var(--zds-size-medium);
        width: var(--zds-size-medium);
        font: var(--zds-body-3);
        border: var(--zds-border-weak);
      }

      &[data-size='large'] {
        height: var(--zds-size-large);
        width: var(--zds-size-large);
        font: var(--zds-paragraph-header-3);
        border: var(--zds-border-weak);
      }
    `,
  ],
  img: css`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
};

/**
 * Displays a user's avatar. Falls back to their initials if an invalid
 * image url or no image url is provided.
 */
export const Avatar = ({
  'aria-hidden': ariaHidden,
  color,
  isBlock,
  isInteractive,
  isSelected,
  name,
  url,
  shouldLazilyLoad = true,
  size = 'small',
}: Props) => {
  // Used to fall back to initials if the image fails to load
  const [hasError, setHasError] = useState(false);
  const initials = name ? getNameInitials(name) : null; // reset hasError if the url changes

  useEffect(() => {
    if (hasError) {
      setHasError(false);
    } // we don't want hasError to trigger this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return (
    <div
      aria-hidden={ariaHidden}
      aria-label={name}
      css={Styles.root(hasError, { url })}
      data-block={isBlock ? 'true' : undefined}
      data-color={color}
      data-interactive={isInteractive ? 'true' : undefined}
      data-selected={isSelected ? 'true' : undefined}
      data-size={size}
      data-testid="Avatar"
      data-zds
      role="img"
    >
      {url && !hasError ? (
        <Img
          css={Styles.img}
          alt={name}
          aria-hidden={true}
          height={SIZES[size]}
          onError={() => setHasError(true)}
          src={url}
          width={SIZES[size]}
          loading={shouldLazilyLoad ? 'lazy' : 'eager'}
        />
      ) : (
        <span>{initials}</span>
      )}
    </div>
  );
};
