/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { labelStyles } from '@zapier/style-encapsulation';

import { Animation, Shadows } from '../../../theme';
import { Link } from '../Link';
import { createUniqueCustomProperties } from '../../../utils/createUniqueCustomProperty';
import {
  InteractiveTagContext,
  InteractiveTagProvider,
} from './InteractiveTagContext';
import { TooltipWrapper } from '../../display/TooltipWrapper';

const Vars = createUniqueCustomProperties('InteractiveTagWrapper', [
  'backgroundColor',
  'backgroundColorHover',
  'backgroundColorFocus',
  'color',
  'colorHover',
  'colorFocus',
  'outlineColor',
  'outlineOpacity',
]);

const Styles = labelStyles('InteractiveTag', {
  root: (maxWidth: string) => css`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: var(--zds-space-8);
    height: var(--zds-size-medium);
    max-width: ${maxWidth};
    padding: var(--zds-space-12);
    font: var(--zds-body-3);
    color: var(${Vars.color});
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background-color: var(${Vars.backgroundColor});
    border-radius: var(--zds-radius-pill);
    transition: ${Animation.transitionValue};

    &:hover {
      color: var(${Vars.colorHover});
      background-color: var(${Vars.backgroundColorHover});
      box-shadow: ${Shadows.elevation6};
    }

    &:focus-visible,
    a:focus-visible & {
      color: var(${Vars.colorFocus});
      background-color: var(${Vars.backgroundColorFocus});
      ${Vars.outlineOpacity}: 1;
      outline: none;

      &::before {
        inset: -4px;
      }
    }

    &[data-color='primary'] {
      border: var(--zds-border-default);
      ${Vars.color}: var(--zds-gray-warm-10);
      ${Vars.colorHover}: var(--zds-text-stronger);
      ${Vars.colorFocus}: var(--zds-text-stronger);
      ${Vars.outlineColor}: var(--zds-text-stronger);
      ${Vars.backgroundColor}: var(--zds-background-stronger);
      ${Vars.backgroundColorHover}: var(--zds-background-stronger);
      ${Vars.backgroundColorFocus}: var(--zds-background-stronger);

      &:hover {
        border-color: var(--zds-stroke-stronger);
      }

      &:focus-visible,
      a:focus-visible & {
        border-color: var(--zds-stroke-stronger);
        outline: none;

        &::before {
          inset: -4px;
        }
      }
    }

    &[data-color='secondary'] {
      border: var(--zds-border-width-default) solid var(--zds-gray-warm-10);
      ${Vars.color}: var(--zds-text-inverted);
      ${Vars.colorHover}: var(--zds-background-weaker);
      ${Vars.colorFocus}: var(--zds-background-weaker);
      ${Vars.backgroundColor}: var(--zds-gray-warm-10);
      ${Vars.outlineColor}: var(--zds-gray-warm-10);
      ${Vars.backgroundColorHover}: var(--zds-gray-warm-9);
      ${Vars.backgroundColorFocus}: var(--zds-gray-warm-10);
    }

    &::before {
      content: '';
      opacity: var(${Vars.outlineOpacity}, 0);
      position: absolute;
      inset: 0;
      border: 2px solid var(${Vars.outlineColor});
      border-radius: inherit;
      transition: inherit;
    }
  `,
});

export type InteractiveTagWrapperProps = {
  /**
   * The content to render within `InteractiveTagWrapper`.
   */
  children: React.ReactNode;
  /**
   * The color of `InteractiveTag`.
   */
  color: 'primary' | 'secondary';
  /**
   * Optional `href` attribute to apply to `InteractiveTagWrapper`
   */
  href?: string;
  /**
   * Optional function to call when `InteractiveTag` is clicked.
   */
  onClick?: React.MouseEventHandler;
  /**
   * Optional max-width of the `InteractiveTagWrapper`.
   * Must be set to a concrete value (i.e. not 100%) to support truncating long content.
   */
  maxWidth?: string;
};

/**
 * Component used to wrap around `InteractiveTag.Content` and `InteractiveTag.Icon`.
 */
export const InteractiveTagWrapper = ({
  children,
  color,
  href,
  onClick,
  maxWidth = 'none',
}: InteractiveTagWrapperProps) => {
  const sharedProps = {
    css: Styles.root(maxWidth),
    'data-color': color,
    onClick,
  };

  const WrappedChildren = () => {
    if (href) {
      return (
        <Link {...sharedProps} color={null} href={href} weight={null}>
          {children}
        </Link>
      );
    }

    return (
      <button {...sharedProps} type="button" data-zds>
        {children}
      </button>
    );
  };

  return (
    <InteractiveTagProvider>
      <InteractiveTagContext.Consumer>
        {(context) => {
          return context?.isTruncated ? (
            <TooltipWrapper
              allowMultilineTooltip={true}
              content={
                context.body
                  ? `${context.title}: ${context.body}`
                  : `${context.title}`
              }
              shouldCorrectPosition={true}
            >
              {() => <WrappedChildren />}
            </TooltipWrapper>
          ) : (
            <WrappedChildren />
          );
        }}
      </InteractiveTagContext.Consumer>
    </InteractiveTagProvider>
  );
};
