// Contains "global" `z-index` values so we can more easily
// manage layering.
// For anyone not super familiar with `z-index`, these values
// are impacted by stacking context and are therefore not _fully_
// global since they simply cannot be. If a value isn't working
// as expected, it's probably due to stacking context. Ask a
// frontend dev or designer for help!
export const Zindexes = {
  closeButton: 1,
  supportButtons: 12,
  npsSurvey: 99,
  stickyHeader: 99,
  dropdownMenu: 900,
  blogDialogContainer: 901,
  asyncOverlay: 901,
  signUp: 949,
  universalNav: 950,
  universalTopbarMegaDropdown: 960,
  popover: 1002,
  editorErrorList: 1002,
  modalOverlay: 2000,
  headerFixedPosition: 10000,
  pageNotification: 10100,
  perfReport: 1000000,
  // To defeat the 1000200 of the social share dock
  fixedSurvey: 1000201,
};
