import { useEffect, useState } from 'react';

type OutsideEvent = MouseEvent | TouchEvent;

export type OnClickOutside = (event: OutsideEvent) => void;

type UseClickOutsideReturn = [(element: Element | null) => void];

export type EventTypes =
  | 'mousedown'
  | 'touchend'
  | 'click'
  | 'touchstart'
  | 'pointerdown';

export type OnClickOutsideProps = {
  onClickOutside: OnClickOutside;
  events?: Array<EventTypes>;
};

export const useClickOutside = ({
  onClickOutside,
  events = ['click', 'touchend'],
}: OnClickOutsideProps): UseClickOutsideReturn => {
  const [node, setNode] = useState<Node | null>(null);
  useEffect(() => {
    const onClick = (event: OutsideEvent) => {
      if (node && !node.contains(event.target as Node)) {
        onClickOutside(event);
      }
    };
    // Checks for SSR even though `useEffect` won't run server-side.
    // Better safe than lo siento.
    const body = document && document.body;
    if (body) {
      // Binding to `document.body` so clicks that initiated rendering
      // a component that uses `useEffect` aren't still bubbling and then
      // fire `onClick` above.
      for (const event of events) {
        body.addEventListener(event, onClick, { capture: true });
      }

      return () => {
        for (const event of events) {
          body.removeEventListener(event, onClick, { capture: true });
        }
      };
    }

    return () => {};
  }, [node, onClickOutside, events]);
  return [setNode];
};
