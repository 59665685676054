// @flow weak

import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class'; // eslint-disable-line no-restricted-syntax
import { RouterHistoryContainer } from 'redux-router-kit';
import { QueryClient, QueryClientProvider } from 'react-query';

// TODO: import '@zapier/design-tokens/custom-properties.css';
// That CSS file is exported via the exports section in the package.json file of
// @zapier/design-tokens. But this repo is still on Webpack 4 which doesn't
// parse the section. As a workaround the file is copied and imported.
import 'app/custom-properties.css';
import { Global } from '@emotion/core';
import { Typography, ZinniaStylesheet } from '@zapier/design-system';
import {
  GlobalStylesReset,
  ZinniaBetaStylesheet,
} from '@zapier/design-system-beta';
import { NotFound } from '@zapier/common-components';

import CatchErrorContainer from 'app/common/components/CatchErrorContainer';
import ChangeActiveAccountOnTabFocusContainer from 'app/common/components/ChangeActiveAccountOnTabFocusContainer';
import CheckPendingPolicies from 'app/common/components/CheckPendingPolicies';
import GlobalModalHolderContainer from 'app/common/components/GlobalModalHolderContainer';
import LoadingNextRouteIndicator from 'app/common/components/LoadingNextRouteIndicator';
import MaybeShowFocusOutline from 'app/common/components/MaybeShowFocusOutline';
import MaybeTrackLandingOnMountContainer from 'app/track/components/MaybeTrackLandingOnMountContainer';
import PageNotification from 'app/common/components/PageNotification';
import PlatformHeader from 'app/developer-v3/components/PlatformHeader';
import routerActions from 'app/common/components/routerActions';

// Make Intercom tooltip badges look a bit more like Zinnia
const styleIntercomOverride = {
  // Repeat `[class]` as a hack to raise specificity so we don't need
  // `!important` on every rule... Even though it _looks_ like we're using an ID
  // selector, that's actual an attribute selector, which has the same
  // specificity as a class selector, making this whole thing 0.5.0 on the
  // specificity scale.
  'nav [id^="tooltip-anchor-"][class*="intercom"][class][class][class]': {
    ...Typography.SmallPrint3,
    padding: '2px 6px',
    marginTop: '-2px',
    marginLeft: '2px',
    pointerEvents: 'none',
  },
};

const queryClient = new QueryClient();

const Route = routerActions(
  createReactClass({
    displayName: 'Route',

    propTypes: {
      children: PropTypes.node,
      routes: PropTypes.object.isRequired,
      router: PropTypes.object.isRequired,
      routeTo: PropTypes.func.isRequired,
    },

    render() {
      return <React.Fragment>{this.props.children}</React.Fragment>;
    },
  })
);

const Root = createReactClass({
  displayName: 'Root',

  renderNotFound({ router }) {
    return (
      <Route router={router} routes={this.props.routes}>
        <NotFound />
      </Route>
    );
  },

  createElement(Component, props) {
    return <Component {...props} routes={this.props.routes} />;
  },

  renderRoot(props) {
    const { router, children } = props;

    if (!children) {
      throw new Error('No children was passed into renderRoot');
    }

    return (
      <CatchErrorContainer>
        <Route router={router} routes={this.props.routes}>
          {/* show a loading bar when loading an async route */}
          {router.fetch && <LoadingNextRouteIndicator />}
          <MaybeTrackLandingOnMountContainer>
            <QueryClientProvider client={queryClient}>
              <PlatformHeader />
              {children}
            </QueryClientProvider>
          </MaybeTrackLandingOnMountContainer>
        </Route>
      </CatchErrorContainer>
    );
  },

  render() {
    const { routes } = this.props;

    return (
      <React.Fragment>
        <RouterHistoryContainer
          createElement={this.createElement}
          renderNotFound={this.renderNotFound}
          renderRoot={this.renderRoot}
          routes={routes}
          shouldEmitCrossOriginLinks={true}
        />
        <ChangeActiveAccountOnTabFocusContainer />
        <GlobalModalHolderContainer />
        <PageNotification />
        <MaybeShowFocusOutline />
        <CheckPendingPolicies />
        <GlobalStylesReset />
        <ZinniaStylesheet />
        <ZinniaBetaStylesheet />
        <Global styles={styleIntercomOverride} />
      </React.Fragment>
    );
  },
});

export default Root;
