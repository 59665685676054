/** @jsx jsx */
import { jsx } from '@emotion/react';
import { BaseIconButton, Props as BaseIconButtonProps } from './BaseIconButton';

export type Props = BaseIconButtonProps & {
  color?:
    | 'icon-alt'
    | 'icon-ghost'
    | 'icon-primary'
    | 'icon-primary-dark'
    | 'icon-tertiary'
    | 'icon-secondary'
    | 'icon-secondary-dark';
  size?: Exclude<BaseIconButtonProps['size'], 'compact'>;
};

/**
 * <span style="color: #a81227; font-weight: bold;">⚠️ This component is on its way out in favor of [IconButton β](../?path=/docs/beta-iconbutton--docs).</span>
 *
 * `IconButton`s are `Buttons` that render `Icon`s within them for compact spaces.
 * They accept most props that `Button` accepts, although they'll always be `isSquare={true}`.
 *
 * Additionally, `IconButton` renders a `Tooltip` which describes what the `Button` does, which is important for accessibility.
 */
export function IconButton({
  color = 'icon-primary',
  size = 'small',
  tooltipPosition = 'south',
  ...props
}: Props) {
  return (
    <BaseIconButton
      color={color}
      size={size}
      tooltipPosition={tooltipPosition}
      {...props}
    />
  );
}
