/** @jsx jsx */
import { jsx } from '@emotion/react';
import { ReactNode } from 'react';

import { ColorName, Colors, TypographyName, Typography } from '../../../theme';
import {
  ContentSectioningElement,
  InlineTextElement,
  TextContentElement,
} from '../../../ts-types';
import { labelStyles } from '@zapier/style-encapsulation';
import { Heading } from '../Heading';

export type TextElement =
  | ContentSectioningElement
  | TextContentElement
  | InlineTextElement;

type TextProps = {
  /** Optional property for overriding styles with CSS-in-JS. */
  className?: string;
  /** The color of text to render. */
  color?: ColorName;
  /**
   * The type (effectively the "style") of text to render. One of a list of
   * preset types, which correspond exactly to the Zapier Design System.
   */
  type?: TypographyName;
  /**
   * Text to be rendered. Accepts a Node to support minor variations, e.g., a
   * link within a heading, or an `<em>` tag for semantic emphasis.
   */
  children: ReactNode;
  /**
   * Weight override for `type`. There are a finite number of `type`s
   * but there are weight variations for each which can be handled here
   * rather than increasing the overall number of `type`s.
   */
  fontWeight?: 400 | 600 | 700 | 800;
  /** Applies a `margin` to the text. */
  margin?: string;
  /** The HTML tag that the component will render. */
  tag?: TextElement | typeof Heading;
  /**
   * Alignment of the text. Values are intended to match CSS `text-align`
   * values. Intentionally omitting many options until use cases arise, for
   * simplicity. One of: 'inherit', 'left', 'center', 'right'.
   */
  textAlign?: 'inherit' | 'left' | 'center' | 'right';
  /** Applies transition to the text. */
  hasTransition?: boolean;
};

type StyleOptions = {
  type: TypographyName;
  color: ColorName;
  fontWeight?: TextProps['fontWeight'];
  margin?: TextProps['margin'];
  textAlign?: TextProps['textAlign'];
  hasTransition?: TextProps['hasTransition'];
};

const Styles = labelStyles('Text', {
  root: (props: StyleOptions) => [
    Typography[props.type],
    { color: Colors[props.color] },
    props.fontWeight && {
      fontWeight: props.fontWeight,
    },
    {
      margin: props.margin,
      maxWidth: '100%',
      textAlign: props.textAlign,
    },
    props.hasTransition && {
      transition: 'color 0.2s ease-in-out',
    },
  ],
});

/**
 * This component precisely implements the Zapier Design System for text.
 *
 * If you feel the need to add a variation on its styles, your design is likely violating Zapier's style guide.
 */
export const Text = ({
  className,
  color = 'GrayWarm10',
  hasTransition = true,
  tag = 'span',
  textAlign = 'inherit',
  type = 'Body1',
  ...otherProps
}: TextProps) => {
  const props = {
    color,
    hasTransition,
    tag,
    textAlign,
    type,
    ...otherProps,
  } satisfies TextProps;

  return (
    <props.tag className={className} css={Styles.root(props)} data-zds>
      {props.children}
    </props.tag>
  );
};
