/** @jsx jsx */
import { jsx } from '@emotion/react';
import {
  InteractiveTagIcon,
  InteractiveTagIconProps,
} from './InteractiveTagIcon';
import {
  InteractiveTagWrapper,
  InteractiveTagWrapperProps,
} from './InteractiveTagWrapper';

type Props = {
  /**
   * The content to render within `InteractiveTag`.
   */
  children: React.ReactNode;
  /**
   * The color of `InteractiveTag`.
   * @default 'primary'
   */
  color?: InteractiveTagWrapperProps['color'];
  /**
   * Optional `href` attribute to apply to `InteractiveTag`, which
   * will make it render as a link.
   */
  href?: InteractiveTagWrapperProps['href'];
  /**
   * The name of the icon to display after the children.
   */
  iconAfter?: InteractiveTagIconProps['name'];
  /**
   * Acessible attribute to hide iconAfter from screenreaders when the icon is purely decorative.
   * Optional since parent node can accept click events and the icon can have a aria-label.
   */
  iconAfterAriaHidden?: InteractiveTagIconProps['aria-hidden'];
  /**
   * Accessible label for `iconAfter`.
   */
  iconAfterAriaLabel?: InteractiveTagIconProps['aria-label'];
  /**
   * The name of the icon to display before the children.
   */
  iconBefore?: InteractiveTagIconProps['name'];
  /**
   * Acessible attribute to hide iconBefore from screenreaders when the icon is purely decorative.
   * Optional since parent node can accept click events and the icon can have a aria-label.
   */
  iconBeforeAriaHidden?: InteractiveTagIconProps['aria-hidden'];
  /**
   * Accessible label for `iconBefore`.
   */
  iconBeforeAriaLabel?: InteractiveTagIconProps['aria-label'];
  /**
   * Optional function to call when `InteractiveTag` is clicked.
   */
  onClick?: InteractiveTagWrapperProps['onClick'];
  /**
   * Optional max-width of the `InteractiveTagWrapper`.
   * Must be set to a concrete value (i.e. not 100%) to support truncating long content.
   */
  maxWidth?: InteractiveTagWrapperProps['maxWidth'];
};

/**
 * <span style="color: #a81227; font-weight: bold;">⚠️ This component will eventually be deprecated by Chip (coming soon!).</span>
 *
 * `InteractiveTag` is used to denote information under a headline or subheadline, typically for categorization purposes.
 *
 * As its name suggests, it should only be used when it is interactive. If it isn't interactive, consider using `TagLabel` or `Badge` instead.
 */
export const InteractiveTag = ({
  children,
  color = 'primary',
  href,
  iconAfter,
  iconAfterAriaHidden = true,
  iconAfterAriaLabel,
  iconBefore,
  iconBeforeAriaHidden = true,
  iconBeforeAriaLabel,
  onClick,
  maxWidth = 'none',
}: Props) => {
  return (
    <InteractiveTagWrapper
      color={color}
      href={href}
      onClick={onClick}
      maxWidth={maxWidth}
    >
      {iconBefore && (
        <InteractiveTagIcon
          aria-hidden={iconBeforeAriaHidden}
          aria-label={iconBeforeAriaLabel}
          name={iconBefore}
          position="before"
        />
      )}
      {children}
      {iconAfter && (
        <InteractiveTagIcon
          aria-hidden={iconAfterAriaHidden}
          aria-label={iconAfterAriaLabel}
          name={iconAfter}
          position="after"
        />
      )}
    </InteractiveTagWrapper>
  );
};
