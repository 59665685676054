import React, { ReactElement, FunctionComponent, ReactNode } from 'react';

/**
 * Returns a list of ids for all nested children of the @node
 *
 * @param node
 */
export function getChildIds(node: ReactElement): string[] {
  const ids: Array<string> = [];

  React.Children.forEach(node.props.children, child => {
    if (child.props) {
      const { id } = child.props;
      const foundIds = id ? [id] : getChildIds(child);

      ids.push(...foundIds);
    }
  });

  return ids;
}

/**
 * Returns whether a node matches a given component
 *
 * @param SourceComponent
 * @param node
 */
export function isSameComponent(
  SourceComponent: FunctionComponent,
  node: ReactNode
) {
  return (
    React.isValidElement(node) &&
    node.type.toString() === SourceComponent.toString()
  );
}
