import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscBundle = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M21 9h1V3H2v6h17v9.996H5V11H3v9.996h18V9ZM4.222 5v2h15.556V5H4.222Z"
      clipRule="evenodd"
    />
    <path
      fill="#2D2E2E"
      d="m9.5 14.581 3.013-4.083v2.917H14.5l-3.013 4.083v-2.917H9.5Z"
    />
  </svg>
);
export default SvgMiscBundle;
