/** @jsx jsx */

import { ComponentProps } from 'react';

import { Link } from '@zapier/design-system';
import { css, jsx } from '@emotion/core';

const Styles = {
  root: css`
    cursor: pointer !important;
    &,
    &:focus,
    &:hover {
      text-decoration: none !important;
    }
  `,
};

type Props = ComponentProps<typeof Link>;

function BlockLink(props: Props) {
  const {
    children,
    className,
    component,
    href,
    onClick,
    target,
    ...restProps
  } = props;

  return (
    <Link
      {...restProps}
      className={className}
      component={component}
      css={Styles.root}
      href={href}
      onClick={onClick}
      target={target}
    >
      {children}
    </Link>
  );
}

export default BlockLink;
