/** @jsx jsx */

import type { ReactNode } from 'react';
import { css, jsx } from '@emotion/react';
import Markdown from 'markdown-to-jsx';

import { labelStyles } from '@zapier/style-encapsulation';
import {
  Modal,
  ModalContent,
  ModalContentHeader,
  ModalContentBody,
  ModalContentFooter,
} from '../../display/Modal';
import { Button } from '../../navigation/Button';

type Props = {
  /** Title of the policy. */
  title: string;
  /** A description of what's changed in the policy. */
  tldr: string;
  /** URL of the policy page to view more. */
  url?: string;
  /** Callback for when acceptance button is clicked. */
  onAccept: () => void;
  /** Function to render markdown. */
  renderMarkdown?: (markdown: string) => ReactNode;
};

const gap = 'var(--zds-space-16, 15px)';

const Styles = labelStyles('PolicyModal', {
  markdown: css`
    text-align: left;

    p {
      &:not(:last-child) {
        margin: 0 0 ${gap};
      }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: ${gap};
      &:not(:first-of-type) {
        margin-top: ${gap};
      }
    }

    ul {
      margin-left: var(--zds-space-20);
      list-style: disc;
    }
  `,
});

/** A `Modal` component for displaying policies to review and accept. */
export const PolicyModal = ({
  onAccept,
  title,
  tldr,
  url,
  renderMarkdown = (markdown: string) => <Markdown>{markdown}</Markdown>,
}: Props) => {
  return (
    <Modal canClose={false} onClosed={() => {}}>
      <ModalContent maxWidth="70ch">
        <ModalContentHeader>
          <h1 data-zds>We’ve updated the {title}</h1>
        </ModalContentHeader>
        <ModalContentBody scrollable={true}>
          <div css={Styles.markdown} data-zds>
            {renderMarkdown(tldr)}
          </div>
        </ModalContentBody>
        <ModalContentFooter>
          {!!url && (
            <Button color="secondary" href={url} target="_blank">
              Review changes
            </Button>
          )}
          <Button color="primary" hasShadow={true} onClick={onAccept}>
            Accept
          </Button>
        </ModalContentFooter>
      </ModalContent>
    </Modal>
  );
};
