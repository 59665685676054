/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { labelStyles } from '@zapier/style-encapsulation';
import React, { Fragment, useEffect, useState } from 'react';

type Props = {
  /**
   * Something that gives extra information to screen reader users.
   * This is often text, but can be any type of ReactNode.
   */
  children?: React.ReactNode;
};

const noop = () => {};

const Styles = labelStyles('VisuallyHidden', {
  root: css`
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  `,
});

/**
 * A visually hidden component that aids in giving extra context to screen reader users.
 */
export const VisuallyHidden = (props: Props) => {
  const [forceShow, setForceShow] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (process.env.NODE_ENV !== 'production') {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Alt') {
          setForceShow(true);
        }
      };

      const handleKeyUp = () => {
        setForceShow(false);
      };

      window.addEventListener('keydown', handleKeyDown, { capture: true });
      window.addEventListener('keyup', handleKeyUp, { capture: true });

      // Cleanup event listener!
      return () => {
        window.removeEventListener('keydown', handleKeyDown, { capture: true });
        window.removeEventListener('keydown', handleKeyUp, { capture: true });
      };
    } else {
      return noop;
    }
  }, []);

  if (forceShow) {
    return <Fragment>{props.children}</Fragment>;
  }
  return (
    <span css={Styles.root} data-testid="visually-hidden">
      {props.children}
    </span>
  );
};
