import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionReadAloudFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m6 17 5 4h2V4h-2L6 8H2v9h4ZM15 9.89l1.57-1.32a6.92 6.92 0 0 1 0 6.86L15 14.11a4.88 4.88 0 0 0 0-4.22ZM19.48 12a8.89 8.89 0 0 1-1.36 4.73L19.67 18a10.95 10.95 0 0 0 0-12l-1.55 1.3a8.89 8.89 0 0 1 1.36 4.7Z"
    />
  </svg>
);
export default SvgActionReadAloudFill;
