/** @jsx jsx */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/react';

import { createUniqueClassName } from '../../../utils/createUniqueClassName';
import {
  Tooltip,
  TooltipPosition,
  TooltipPositionStyles,
} from '../../display/Tooltip';
import { Animation } from '../../../theme';
import { useIntersect, UseIntersectReturn } from '../../../hooks';
import { labelStyles } from '@zapier/style-encapsulation';
import { uniqueId } from '../../../utils/uniqueId';

type ChildProps = {
  childProps: {
    'aria-describedby'?: string;
  };
  tooltipId?: string;
};

type Props = {
  /** A function to render the wrapped children, returning a Node.
   *
   * It receives an object as an argument with this shape:
   * ```
   * {
   *   childProps: { // props for the rendered children
   *     ariaDescribedBy?: string, // aria attribute to put on the described element
   *   },
   *   // the unique id of the tooltip element
   *   tooltipId: string,
   * }
   * ```
   */
  children: (childProps: ChildProps) => React.ReactNode;
  /** The position to place the tooltip relative to the parent element. */
  position?: TooltipPosition;
  /** The content to render inside of the tooltip. */
  content: React.ReactNode;
  /** Indicates whether to render the component as a block-level element. */
  isBlock?: boolean;
  /** Indicates whether to flip the `Tooltip` based on available space. */
  shouldCorrectPosition?: boolean;
  /**
   * Indicates whether to show tooltips on touch-only devices,
   * i.e. devices that don't support hovering.
   */
  showTooltipOnTouchDevices?: boolean;
  /**
   * Indicates whether the `Tooltip` will be `aria-hidden`. Defaults
   * to `true` because `aria-describedby` will associate the "trigger"
   * to the content of the `Tooltip`, so the `Tooltip` should be hidden
   * from screenreaders to prevent it from being read twice. Set to `false`
   * to allow the `Tooltip` to be accessed directly by screenreaders. This
   * is most useful when the "trigger" node (the one with the `aria-describedby`
   * attribute on it) is _not_ interactive since most screenreaders don't read
   * the content associated via `aria-describedby` when the element with that
   * attribute on it is not interactive (and therefore the screenreader would
   * not read it twice).
   */
  tooltipAriaHidden?: boolean;
  /**
   * Optional prop to associate the tooltip and its control. If one
   * isn't supplied then one will be created. Passing this is helpful
   * when server and client ids are mismatched.
   */
  tooltipId?: string;
  /** Tooltip expands to accommodate content if true. */
  allowMultilineTooltip?: boolean;
  /** The `z-index` of the tooltip. */
  zIndex?: number;
};

// These exist because `onMouseLeave` doesn't fire properly when
// the node that is returned from `children()` is `disabled`.
// See https://github.com/facebook/react/issues/4251
//
// To work around it, always render `Tooltip` but hide it and
// use `:hover` and `:focus-within` which are arguably more
// appropriate for this than JS. In order to actually show
// the node it has to have a consistent `class` to select,
// so that's what this is for.
const contentNodeClassName = createUniqueClassName('TooltipWrapper', 'content');
const contentNodeSelector = `.${contentNodeClassName}` + '[class]'.repeat(5);

const Styles = labelStyles('TooltipWrapper', {
  root: (props: Props, correctedPosition: TooltipPosition) => [
    css`
      position: relative;
      display: inline-block;

      ${contentNodeSelector} {
        z-index: ${props.zIndex};
        position: absolute;
        ${correctedPosition ? TooltipPositionStyles[correctedPosition] : null};
        opacity: 0;
        pointer-events: none;

        @media (prefers-reduced-motion: no-preference) {
          transition: ${Animation.transitionValue};
        }
      }

      // Edge doesnt understand ':focus-within' and will therefore
      // ignore the styles if theyre comboed like '&:hover, &:focus-within'.
      // As a workaround, separate the duplicated styles.
      &:hover ${contentNodeSelector} {
        opacity: 1;
        pointer-events: auto;
      }

      &:focus-within ${contentNodeSelector} {
        opacity: 1;
        pointer-events: auto;
      }

      ${contentNodeSelector}[class] {
        pointer-events: none;
      }
    `,

    !props.showTooltipOnTouchDevices &&
      css`
        @media (hover: none) {
          ${contentNodeSelector}[class] {
            label: -hide-tooltip-on-touch-devices;
            opacity: 0;
          }
        }
      `,

    props.isBlock &&
      css`
        label: -block;
        display: block;
      `,
  ],
});

const correctPosition = (
  position: TooltipPosition,
  result: UseIntersectReturn[1]
) => {
  const {
    isTopIntersecting,
    isBottomIntersecting,
    isLeftIntersecting,
    isRightIntersecting,
  } = result;
  if (isBottomIntersecting && position.includes('south')) {
    return position.replace('south', 'north') as TooltipPosition;
  } else if (isTopIntersecting && position.includes('north')) {
    return position.replace('north', 'south') as TooltipPosition;
  } else if (isLeftIntersecting && position.includes('west')) {
    return position.replace('west', 'east') as TooltipPosition;
  } else if (isRightIntersecting && position.includes('east')) {
    return position.replace('east', 'west') as TooltipPosition;
  }
  return position;
};

/**
 * Renders a `Tooltip` when the wrapped component is hovered or focused.
 */
export const TooltipWrapper = ({
  children,
  content,
  allowMultilineTooltip = undefined,
  isBlock = undefined,
  position = 'south',
  shouldCorrectPosition = true,
  showTooltipOnTouchDevices = true,
  tooltipAriaHidden = true,
  tooltipId: tooltipIdProp = undefined,
  zIndex = 10,
}: Props) => {
  const props = {
    children,
    content,
    allowMultilineTooltip,
    isBlock,
    position,
    shouldCorrectPosition,
    showTooltipOnTouchDevices,
    tooltipAriaHidden,
    tooltipId: tooltipIdProp,
    zIndex,
  } satisfies Props;

  const [tooltipId] = useState(props.tooltipId || uniqueId('tooltip-'));
  const shouldRenderTooltip = !!content;
  const childProps = {
    'aria-describedby': shouldRenderTooltip ? tooltipId : undefined,
  };

  // Detect if the Tooltip would be rendered outside the vertical bounds
  // of the screen and if so invert the position.
  const threshold = 0.9;
  const rootMargin = `-25px`;
  const [setNode, result] = useIntersect({
    threshold,
    rootMargin,
  });
  const correctedPosition =
    props.shouldCorrectPosition && result && Object.keys(result).length > 0
      ? correctPosition(position, result)
      : position;

  return (
    <div css={Styles.root(props, correctedPosition) as any} ref={setNode}>
      {children({
        childProps,
        tooltipId,
      })}
      {shouldRenderTooltip && (
        // Add `ariaHidden to `Tooltip` to prevent it from being accessible
        // and redundantly read by screenreaders.
        <div
          className={contentNodeClassName}
          data-testid={`TooltipWrapper__content--${correctedPosition}`}
          data-zds
        >
          <Tooltip
            allowMultiline={props.allowMultilineTooltip}
            aria-hidden={props.tooltipAriaHidden}
            id={tooltipId}
            position={null}
          >
            {content}
          </Tooltip>
        </div>
      )}
    </div>
  );
};
