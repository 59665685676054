import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionEditAiFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M3 21v-4.91L14.09 5 19 9.91 7.91 21H3ZM15.51 3.58l1.41-1.41 4.91 4.91-1.41 1.41-4.91-4.91ZM18.483 14l1.28 2.202 2.203 1.281-2.202 1.28-1.28 2.203-1.282-2.202L15 17.484l2.202-1.282L18.483 14Z"
    />
  </svg>
);
export default SvgActionEditAiFill;
