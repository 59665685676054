/** @jsx jsx */
import React from 'react';
import { css, jsx, keyframes } from '@emotion/react';
import { useDimensions } from '../../../hooks';

import { Animation, Colors, Shadows } from '../../../theme';

import { labelStyles } from '@zapier/style-encapsulation';

export type TooltipPosition =
  | 'northwest'
  | 'north'
  | 'northeast'
  | 'east'
  | 'southeast'
  | 'south'
  | 'southwest'
  | 'west';

type Props = {
  /** Tooltip expands to accomodate content if true. */
  allowMultiline?: boolean;
  /**
   * Indicates whether the content is hidden from screenreaders.
   * This is useful when the `Tooltip` has already been associated
   * to a node via `aria-describedby` to prevent screenreaders from
   * reading the content of the `Tooltip` twice.
   *
   * Note that some screenreaders like VoiceOver only read content
   * associated via `aria-describedby` when the element with that
   * attribute is interactive, like a `button` element.
   */
  'aria-hidden'?: boolean;
  /** The content to render inside of the Tooltip. */
  children: React.ReactNode;
  /** The position to place the tooltip relative to the parent element. */
  position?: TooltipPosition | null;
  /** Unique id, intended for use with `aria-describedby`. */
  id?: string;
  /** The z-index of the tooltip. */
  zIndex?: number;
};

const distance = 'var(--zds-space-2)';

const tooltipOpenAnimation = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
});

export const positionStyles = {
  northwest: css`
    bottom: calc(100% + ${distance});
    left: 0;
  `,

  north: css`
    bottom: calc(100% + ${distance});
    left: 50%;
    transform: translateX(-50%);
  `,

  northeast: css`
    bottom: calc(100% + ${distance});
    right: 0;
  `,

  east: css`
    left: calc(100% + ${distance});
    top: 50%;
    transform: translateY(-50%);
  `,

  southeast: css`
    top: calc(100% + ${distance});
    right: 0;
  `,

  south: css`
    top: calc(100% + ${distance});
    left: 50%;
    transform: translateX(-50%);
  `,

  southwest: css`
    top: calc(100% + ${distance});
    left: 0;
  `,

  west: css`
    right: calc(100% + ${distance});
    top: 50%;
    transform: translateY(-50%);
  `,
};
// V5 line-height plus top and bottom padding
const singleLineHeightPlusPadding = 28;

const Styles = labelStyles('Tooltip', {
  root: (
    allowMultiline: Props['allowMultiline'],
    isMultiline: boolean,
    position: Props['position'],
    zIndex: Props['zIndex']
  ) => [
    css`
      display: block;
      color: ${Colors.TextInverted};
      text-align: center;
      border-radius: var(--zds-radius-medium);
      position: relative;
      z-index: ${zIndex};
      box-shadow: ${Shadows.elevation30};
      background-color: ${Colors.GrayWarm10};
      width: max-content;
      max-width: 300px;
      font: var(--zds-body-1);
      padding: 2px 11px;

      @media (prefers-reduced-motion: no-preference) {
        animation: ${Animation.transitionDuration}
          ${Animation.transitionTimingFunction} ${tooltipOpenAnimation};
      }

      pointer-events: none;

      // Increase mouse area so tooltip doesn't disappear when
      // mousing over a gap between its toggle and the tooltip itself.
      &::before {
        content: '';
        position: absolute;
        top: -${distance};
        right: -${distance};
        bottom: -${distance};
        left: -${distance};
        z-index: -1;
      }
    `,

    position &&
      css`
        label: -${position};
        position: absolute;
        ${positionStyles[position]};
      `,

    isMultiline &&
      css`
        label: -multiline;
        border-radius: var(--zds-radius-medium);
        padding: var(--zds-space-12) var(--zds-space-16);
        text-align: left;
      `,

    !allowMultiline &&
      css`
        label: -single-line;
        height: ${singleLineHeightPlusPadding}px;
        padding: 2px 11px;
      `,
  ],

  content: (allowMultiline: Props['allowMultiline']) => [
    css`
      display: block;
    `,

    // Separate node to truncate content. It's important
    // to apply `overflow: hidden` here and not on `root`
    // otherwise the `::before` will be clipped.
    !allowMultiline &&
      css`
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      `,
  ],
});

/**
 * A floating tooltip to display additional information.
 *
 * Can be used on its own, or rendered by the `TooltipWrapper`.
 */
export const Tooltip = ({
  children,
  allowMultiline = false,
  'aria-hidden': ariaHidden = undefined,
  id = undefined,
  position = 'south',
  zIndex = 2,
}: Props) => {
  /**
   * Get the height of the tooltip to determine if it has multiple lines.
   * The tooltip's content could only be one line but have the `allowMultiline` prop set to true,
   * so we need the height to know for sure if the tooltip has multiple lines.
   */
  const [ref, { height }] = useDimensions();
  const isMultiline = height > singleLineHeightPlusPadding;

  return (
    <span
      aria-hidden={ariaHidden}
      css={Styles.root(allowMultiline, isMultiline, position, zIndex)}
      data-testid={`tooltip-${position}`}
      data-zds
      id={id}
      ref={ref}
      role="tooltip"
    >
      <span css={Styles.content(allowMultiline)} data-zds>
        {children}
      </span>
    </span>
  );
};
