/** @jsx jsx */

import {
  Avatar,
  Colors,
  FloatingBox,
  Menu,
  MenuItem,
} from '@zapier/design-system';
import { css, jsx } from '@emotion/core';

const Styles = {
  avatarButton: css`
    background-color: transparent !important;
    border: none !important;
    cursor: pointer !important;
  `,
  divider: css`
    background-color: ${Colors.GrayWarm3};
    border: 0px;
    height: 1px;
    margin: 5px 0px;
    width: 100%;
  `,
  menuWrapper: css`
    padding: 10px;
  `,
  separator: css`
    border-top: 1px solid ${Colors.GrayWarm3};
  `,
};

type Props = {
  isOpen: boolean;
  onClickLogOut?: () => void;
  onClickSettings?: () => void;
  onClickZapier?: () => void;
  onToggle: () => void;
  urlLogOut: string;
  urlSettings: string;
  urlZapier: string;
  user: {
    avatarUrl: string;
    name: string;
  };
};

const UserDropdown = ({
  isOpen,
  onClickLogOut,
  onClickSettings,
  onClickZapier,
  onToggle,
  urlLogOut,
  urlSettings,
  urlZapier,
  user,
}: Props) => {
  return (
    <nav>
      <button css={Styles.avatarButton} onClick={() => onToggle()}>
        <Avatar
          isSelected={isOpen}
          name={user.name}
          size="medium"
          url={user.avatarUrl}
        />
      </button>
      {isOpen && (
        <FloatingBox
          align="right"
          hasWhiteBackground={true}
          minWidth="198px"
          onClickOutside={event => {
            event.stopPropagation();
            onToggle();
          }}
          position="south"
        >
          <div css={Styles.menuWrapper}>
            <Menu aria-label="User options">
              <MenuItem href={urlSettings} onClick={onClickSettings}>
                Settings
              </MenuItem>
              <MenuItem href={urlZapier} onClick={onClickZapier}>
                Zapier.com
              </MenuItem>
              <hr css={Styles.divider} />
              <MenuItem href={urlLogOut} onClick={onClickLogOut}>
                Log out
              </MenuItem>
            </Menu>
          </div>
        </FloatingBox>
      )}
    </nav>
  );
};

export default UserDropdown;
