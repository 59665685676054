import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavGridView = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M11 11H3V3h8v8ZM9 5H5v4h4V5ZM11 21H3v-8h8v8Zm-2-6H5v4h4v-4ZM13 21h8v-8h-8v8Zm2-6h4v4h-4v-4ZM21 11h-8V3h8v8Zm-2-6h-4v4h4V5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNavGridView;
