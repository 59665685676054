/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/react';

import { labelStyles } from '@zapier/style-encapsulation';

import { Typography } from '../../../theme';
import { FormLabelRequiredText } from './FormLabelRequiredText';

type Props = {
  /**
   * How to vertically align the items.
   */
  alignItems?: 'center' | 'start';
  /**
   * The content to render within `FormLabel`.
   * May be text or nodes containing text, an `input`, etc.
   */
  children: React.ReactNode;
  /**
   * The `for` attribute to attach to the `label` node, associating it to
   * an external input by its `id`.
   */
  htmlFor?: string;
  /**
   * Indicates whether the input the `FormLabel` controls is disabled.
   */
  isDisabled?: boolean;
  /**
   * Indicates whether the input the `FormLabel` controls is errored.
   */
  isErrored?: boolean;
  /**
   * Indicates whether the input the `FormLabel` controls is required.
   */
  isRequired?: boolean;
  /**
   * Indicates whether the input the `FormLabel` controls is selected.
   * Typically used in conjunction with `BooleanInput`.
   */
  isSelected?: boolean;
  /**
   * How to horizontally justify the content.
   */
  justifyContent?: 'start' | 'stretch';
  /**
   * Optional text to render inside the required label.
   */
  requiredText?: React.ReactNode;
  /**
   * Size of the `FormLabel`.
   */
  size?: 'compact' | 'small' | 'medium';
};

const Styles = labelStyles('FormLabel', {
  root: css`
    display: grid;
    justify-content: start;
    grid-gap: var(--zds-space-2);
    grid-auto-flow: column;
    grid-auto-columns: auto;
    cursor: pointer;
    color: var(--zds-text-default);

    &[data-align-items='center'] {
      align-items: center;
    }

    &[data-align-items='start'] {
      align-items: start;
    }

    &[data-justify-content='start'] {
      justify-content: start;
    }

    &[data-justify-content='stretch'] {
      justify-content: stretch;
    }

    &[data-state~='selected'] {
      color: var(--zds-text-default);
    }

    &[data-state~='errored'] {
      color: var(--zds-status-error-stronger);
    }

    &[data-state~='disabled'] {
      cursor: not-allowed;
      color: var(--zds-text-weakest);
    }

    &[data-size='compact'] {
      ${Typography.MinimalPrint3}
    }

    &[data-size='small'] {
      ${Typography.SmallPrint3}
    }

    &[data-size='medium'] {
      ${Typography.Body2}
    }

    &[data-size='medium'][data-state='selected'] {
      ${Typography.Body3}
    }
  `,
  asterisk: css`
    ${Typography.Body2Multiline}
    color: var(--zds-ui-brand);
    text-decoration: none;
  `,
});

/**
 * A styled form label. Wrap form components with it to attach `label` text, especially for accessibility.
 */
export const FormLabel = ({
  children,
  alignItems = 'center',
  htmlFor = undefined,
  isDisabled = undefined,
  isErrored = undefined,
  isRequired = undefined,
  isSelected = undefined,
  justifyContent = 'start',
  requiredText = undefined,
  size = 'medium',
}: Props) => {
  return (
    <label
      css={Styles.root}
      data-align-items={alignItems}
      data-justify-content={justifyContent}
      data-size={size}
      data-state={
        [
          isSelected && 'selected',
          isErrored && 'errored',
          isDisabled && 'disabled',
        ]
          .filter(Boolean)
          .join(' ') || undefined
      }
      data-zds
      htmlFor={htmlFor}
    >
      {children}
      {isRequired && (
        <abbr css={Styles.asterisk} title="required">
          *
        </abbr>
      )}
      {isRequired ? (
        requiredText ? (
          <FormLabelRequiredText isDisabled={isDisabled}>
            {requiredText}
          </FormLabelRequiredText>
        ) : (
          <FormLabelRequiredText isDisabled={isDisabled} />
        )
      ) : null}
    </label>
  );
};
