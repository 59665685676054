import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionReadAloud = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M16.57 7.57 15 8.89a4.88 4.88 0 0 1 0 4.22l1.57 1.32a6.92 6.92 0 0 0 0-6.86ZM19.48 11a8.89 8.89 0 0 1-1.36 4.73L19.67 17a10.95 10.95 0 0 0 0-12l-1.55 1.3a8.89 8.89 0 0 1 1.36 4.7ZM11 20l-5-4H2v-2h5l4 3V6L7 9H4v3H2V7h4l5-4h2v17h-2Z"
    />
  </svg>
);
export default SvgActionReadAloud;
