/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Colors, Typography } from '../../../theme';
import { labelStyles } from '@zapier/style-encapsulation';

type Props = {
  /**
   * The content to render within `FormLabelRequiredText`.
   *
   * @default Required
   */
  children?: React.ReactNode;

  /**
   * Whether or not this should render with "disabled" styling.
   *
   * @default false
   */
  isDisabled?: boolean;
};

const Styles = labelStyles('FormLabelRequiredText', {
  root: css`
    ${Typography.SmallPrint1}
    color: var(--zds-text-default, ${Colors.GrayWarm9});
    text-transform: lowercase;

    &::before {
      content: '(';
    }

    &::after {
      content: ')';
    }

    &[data-state='disabled'] {
      color: var(--zds-text-weakest, ${Colors.GrayWarm5});
    }
  `,
});

/**
 * `FormLabelRequiredText` indicates whether a field is required.
 * Should always be a descendant of a `Label`.
 */
export const FormLabelRequiredText = ({
  children = 'Required',
  isDisabled = false,
}: Props) => {
  return (
    // `aria-hidden` to prevent it from being read redundantly.
    // The associated `input` field should indicate whether it's
    // required to screenreaders.
    <span
      aria-hidden="true"
      css={Styles.root}
      data-state={isDisabled ? 'disabled' : undefined}
      data-zds
    >
      {children}
    </span>
  );
};
