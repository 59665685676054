/** @jsx jsx */
import { jsx } from '@emotion/react';
import { labelStyles, withModifier } from '@zapier/style-encapsulation';
import { Link } from '../../navigation/Link';
import type { LinkProps } from '../../navigation/Link';
import { Typography } from '../../../theme';

export type Props = {
  /**
   * A string that names the menu item.
   *
   * **Should only be defined if the item's contents don't provide a useful accessible name and no visible content is available to provide the accessible name**.
   */
  'aria-label'?: string;
  /**
   * An ID that Identifies the element that names the menu item.
   *
   * **Should only be defined if the item's contents don't provide a useful accessible name and a different element's contents can be used to provide the accessible name**.
   */
  'aria-labelledby'?: string;
  /**
   * What to render in the `MenuItem`.
   */
  children: React.ReactNode;
  /** A Component to pass into the `MenuItem` if you'd like to render a different kind of link,
   * like a Router Link, for example
   * Only if the `Tag` is a link
   */
  component?: LinkProps['component'];
  /**
   * Indicates whether the item should be disabled
   */
  disabled?: boolean;
  /**
   * Optional property which, when supplied, will render `MenuItem` as an `a` tag.
   */
  href?: string;
  /**
   * Whether the item is currently selected. Should be used in circumstances
   * where items are ephemerally chosen.
   */
  isSelected?: boolean;
  /**
   * Optional `id` so the node can be referenced.
   */
  id?: string;
  /**
   * Optional `rel` to be applied to the menu item if it is rendered as an anchor tag and not a button.
   */
  rel?: string;
  /**
   * The optional a11y role for the item. `menuitem` is the semantic equivalent of `<li>`
   *  and should be used when the parent has a role of 'menu',
   *  while 'option' is the semantic equivalent of `<option>`and
   *  should be used when the parent has the role of 'listbox'.
   */
  role?: 'option' | 'menuitem';
  /**
   * Optional click handler for the `MenuItem`.
   */
  onClick?: (event: React.MouseEvent) => void;
};

const Styles = labelStyles('MenuItem', {
  content: (props: Props) => [
    {
      ...Typography.Body2,
      alignItems: 'center',
      borderRadius: 'var(--zds-radius-small)',
      color: 'var(--zds-gray-warm-9)',
      display: 'flex',
      height: 'var(--zds-size-medium)',
      lineHeight: 'var(--zds-size-medium)',
      padding: '0 var(--zds-space-12)',
      textDecoration: 'none',
      outline: 'none',
      width: '100%',

      // Bump specificity with `[class]` since these states
      // should have precedence over `isSelected`.
      '&:not([disabled]):hover, &:not([disabled]):focus': {
        backgroundColor: 'var(--zds-ui-primary-weakest)',
        color: 'var(--zds-ui-primary-stronger)',
        fontWeight: props.isSelected ? 600 : 500,
      },

      '&[disabled]': {
        color: 'var(--zds-gray-warm-5)',
        cursor: 'not-allowed',
      },
    },

    props.isSelected &&
      withModifier('selected', {
        backgroundColor: 'var(--zds-gray-warm-2)',
        fontWeight: 600,
      }),
  ],

  text: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export const MenuItem = (props: Props) => {
  const Tag = props.href && !props.disabled ? Link : 'button';
  const isLink = Tag === Link;
  const ariaLabel = props.isSelected
    ? `${props['aria-label'] || props.children}, selected`
    : props['aria-label'];
  const conditionalProps = isLink
    ? {
        'aria-label': ariaLabel,
        href: props.href,
        rel: props.rel,
      }
    : {
        'aria-label': ariaLabel,
        type: 'button' as 'button',
        disabled: props.disabled,
      };
  const liRole = props.role ? { role: 'none' } : null;

  return (
    <li {...liRole} data-zds>
      <Tag
        {...conditionalProps}
        component={isLink ? props.component : undefined}
        data-zds
        // @ts-ignore doesn't understand Link[color=null]
        color={isLink ? null : undefined}
        css={Styles.content(props)}
        href={props.href}
        onClick={props.onClick}
        role={props.role}
      >
        <span css={Styles.text} data-zds>
          {props.children}
        </span>
      </Tag>
    </li>
  );
};
