import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgArrowResizeDiagonalLeft = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m6.124 6.124-.566 6.505 1.846 1.846.572-6.498 6.499-.573-1.846-1.846-6.505.566Zm3.401 10.472 1.845 1.846 6.506-.566.566-6.505-1.846-1.846-.573 6.498-6.498.573Z"
    />
  </svg>
);
export default SvgArrowResizeDiagonalLeft;
