import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscCopilot = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M6 11h.385A4.615 4.615 0 0 0 11 6.384V6h2v.384A4.615 4.615 0 0 0 17.616 11H18v2h-.384A4.615 4.615 0 0 0 13 17.615V18h-2v-.385A4.615 4.615 0 0 0 6.385 13H6v-2ZM4.054 11.022H2.043A10.003 10.003 0 0 1 11 2.028v2.013a8.004 8.004 0 0 0-6.946 6.98ZM2.051 13.022h2.014A8.004 8.004 0 0 0 11 19.917v2.013a10.003 10.003 0 0 1-8.949-8.908ZM13 21.93a10.003 10.003 0 0 0 8.944-8.908H19.93A8.004 8.004 0 0 1 13 19.917v2.012ZM21.953 11.022A10.003 10.003 0 0 0 13 2.029V4.04a8.004 8.004 0 0 1 6.941 6.98h2.012Z"
    />
  </svg>
);
export default SvgMiscCopilot;
