import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscTrigger = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M11.373 12.09h2.53v1.548H8.032L17.018 3v7.543h4.314l-8.986 10.639v-5.996h1.557v1.74l4.089-4.835H15.46V7.255l-4.088 4.836ZM2 13.417h5.34v-1H2v1Z"
    />
    <path
      fill="#2D2E2E"
      d="M10.677 16.07h-8.01v-1h8.01v1ZM4.002 10.765h5.34v-1h-5.34v1Z"
    />
  </svg>
);
export default SvgMiscTrigger;
