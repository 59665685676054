import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgArrowContract = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12.243 10.9 8 6.656V3.828l3.246 3.246L11.238 2h2.009l-.007 5.074 3.245-3.246v2.829l-4.242 4.242ZM12.243 13 8 17.243v2.828l3.246-3.246-.008 5.075h2.009l-.007-5.075 3.245 3.246v-2.828L12.243 13Z"
    />
  </svg>
);
export default SvgArrowContract;
