/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Props as BaseButtonProps, BaseButton } from './BaseButton';

export type Props = Omit<BaseButtonProps, 'isPill'> & {
  color?:
    | 'brand'
    | 'danger'
    | 'ghost'
    | 'primary'
    | 'primary-alt'
    | 'primary-dark'
    | 'secondary'
    | 'secondary-dark'
    | 'tertiary';
};

/**
 * <span style="color: #a81227; font-weight: bold;">⚠️ This component is on its way out in favor of [Button β](../?path=/docs/beta-button--docs).</span>
 *
 * Classic button component. Use when providing calls to action to users.
 */
export function Button({
  color = 'primary',
  size = 'medium',
  ...props
}: Props) {
  return <BaseButton color={color} size={size} {...props} />;
}
