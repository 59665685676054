import Tokens from '@zapier/design-tokens';

/**
 * An object containing legacy and PDL typography CSS font properties. The properties can be applied inline or with CSS-in-JS.
 *
 * @example
 * <p css={{ ...Typography.PageHeader }}>Styled with Emotion</p>
 * @example
 * <p style={{ ...Typography.PageHeader }}>Styled with inline styles</p>
 */
export const Typography = {
  // For use cases where styles are unique but the same
  // `fontFamily` should be used.
  base: {
    fontFamily: Tokens.Inter,
  },
  heading: {
    fontFamily: Tokens.Degular,
  },

  PageHeader: {
    font: Tokens.PageHeader,
    letterSpacing: Tokens.LetterSpacingHeader,
  },
  SectionHeader: {
    font: Tokens.SectionHeader,
    letterSpacing: Tokens.LetterSpacingHeader,
  },
  SectionHeaderMultiline: {
    font: Tokens.SectionHeaderMultiline,
    letterSpacing: Tokens.LetterSpacingHeader,
  },
  ParagraphHeader4: {
    font: Tokens.ParagraphHeader4,
  },
  ParagraphHeader3: {
    font: Tokens.ParagraphHeader3,
  },
  ParagraphHeader2: {
    font: Tokens.ParagraphHeader2,
  },
  ParagraphHeader1: {
    font: Tokens.ParagraphHeader1,
  },
  Title: {
    font: Tokens.Title,
    letterSpacing: Tokens.LetterSpacingHeader,
  },
  Body4: {
    font: Tokens.Body4,
  },
  Body4Multiline: {
    font: Tokens.Body4Multiline,
  },
  Body3: {
    font: Tokens.Body3,
  },
  Body3Multiline: {
    font: Tokens.Body3Multiline,
  },
  Body2: {
    font: Tokens.Body2,
  },
  Body2Multiline: {
    font: Tokens.Body2Multiline,
  },
  Body1: {
    font: Tokens.Body1,
  },
  Body1Multiline: {
    font: Tokens.Body1Multiline,
  },
  SmallPrint4: {
    font: Tokens.SmallPrint4,
  },
  SmallPrint4Multiline: {
    font: Tokens.SmallPrint4Multiline,
  },
  SmallPrint3: {
    font: Tokens.SmallPrint3,
  },
  SmallPrint3Multiline: {
    font: Tokens.SmallPrint3Multiline,
  },
  SmallPrint2: {
    font: Tokens.SmallPrint2,
  },
  SmallPrint2Multiline: {
    font: Tokens.SmallPrint2Multiline,
  },
  SmallPrint1: {
    font: Tokens.SmallPrint1,
  },
  SmallPrint1Multiline: {
    font: Tokens.SmallPrint1Multiline,
  },
  MinimalPrint4: {
    font: Tokens.MinimalPrint4,
  },
  MinimalPrint4Multiline: {
    font: Tokens.MinimalPrint4Multiline,
  },
  MinimalPrint3: {
    font: Tokens.MinimalPrint3,
  },
  MinimalPrint3Multiline: {
    font: Tokens.MinimalPrint3Multiline,
  },
  MinimalPrint2: {
    font: Tokens.MinimalPrint2,
  },
  MinimalPrint2Multiline: {
    font: Tokens.MinimalPrint2Multiline,
  },
  MinimalPrint1: {
    font: Tokens.MinimalPrint1,
  },
  MinimalPrint1Multiline: {
    font: Tokens.MinimalPrint1Multiline,
  },
} as const;

export type TypographyName = keyof typeof Typography;
