/** @jsx jsx */

import { css, jsx } from '@emotion/core';

import { Zindexes } from '@zapier/theme';
import { Alert } from '@zapier/design-system';
import { Timeout } from '@zapier/common-components';

import { TRANSITION_DURATION } from './constants';

const Styles = {
  root: css`
    margin-bottom: 10px;
    max-width: 90vw;
    transition-duration: ${TRANSITION_DURATION}ms;
    transition-property: transform;
    transition-timing-function: ease-in-out;
    z-index: ${Zindexes.pageNotification};
  `,
};

type Props = {
  dismissAfter: number;
  id: number;
  message:
    | {
        title: string;
        body: string;
      }
    | string;
  onDismiss: (id?: number) => void;
  type: 'success' | 'failure';
};

function Notification(props: Props) {
  const { dismissAfter, id, message, onDismiss, type } = props;

  const notification = (
    <div css={Styles.root}>
      <Alert
        onDismiss={() => onDismiss(id)}
        status={type === 'failure' ? 'error' : type}
        title={typeof message !== 'string' && message.title}
        variant="alert"
      >
        {typeof message === 'string' ? message : message?.body}
      </Alert>
    </div>
  );

  return dismissAfter === Infinity ? (
    notification
  ) : (
    <Timeout
      delay={dismissAfter}
      isWaiting={true}
      onTimeout={() => onDismiss(id)}
    >
      {notification}
    </Timeout>
  );
}

export default Notification;
