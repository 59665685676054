/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { Component } from 'react';
import { labelStyles, withModifier } from '@zapier/style-encapsulation';
import * as Icons from './compiled';

import { Animation, ColorName, Colors } from '../../../theme';
import { IconName } from './types';
import { type LiteralUnion } from '@/types';

export type Props = {
  /**
   * Accessible label for the icon. Optional since in many cases the
   * parent node will already have an `ariaLabel` attached.
   */
  'aria-label'?: string;
  /**
   * Accessible attribute to hide the icon when the only role is presentation.
   * Optional since parent node can accept click events and the icon can have a aria-label. Default is true.
   */
  'aria-hidden'?: boolean;
  /**
   * Indicates whether `pointer-events` are accepted on this component.
   * `Icon` nodes can interfere with event firing, and disabling
   * `pointer-events` can prevent the interference. Generally the `Icon`
   * shouldn't accept events itself, but rather its parent should for
   * accessibility purposes.
   */
  canAcceptPointerEvents?: boolean;
  /**
   * Icon fill color. If this is not specified, the color will be inherited value of the CSS `color` property.
   */
  color?: ColorName;
  /**
   * Indicates whether the `Icon` is `display: block`, which removes
   * `line-height` related layout from it.
   */
  isBlock?: boolean;
  /**
   * String representing the name of icon to display. If the name is invalid, a square will be rendered instead.
   */
  name: IconName;
  /**
   * Whether or not the fill property should animate.
   */
  shouldAnimateFill?: boolean;
  /**
   * Size of the icon. This value will be the width and height of the icon, since it is a square.
   *
   * Acceptable values:
   * - small (24px), medium (36px), or large (48px)
   * - a number (in pixels)
   * - a string specifying css units
   */
  size?: LiteralUnion<'small' | 'medium' | 'large', number | string>;
};

const sanitizeClassName = (size: string | number) => {
  return `${size}`.replace(/[^a-z0-9]/gi, '-');
};

const Styles = labelStyles('Icon', {
  root: (
    props: Pick<
      Props,
      | 'name'
      | 'canAcceptPointerEvents'
      | 'isBlock'
      | 'color'
      | 'shouldAnimateFill'
      | 'size'
    >
  ) => [
    {
      label: `-${props.name}`,
      display: 'inline-block',
      fill: 'currentColor',

      '> svg': {
        display: 'block',
        height: 'inherit',
        width: 'inherit',
      },
      '*': {
        fill: 'inherit',
      },
    },
    // Though this component doesn't manage active state on its own such that
    // transition styles are necessary, its parent might. When a parent is
    // toggling this component's `color` prop, we need to animate that
    // consistently with our other transitions.
    props.shouldAnimateFill &&
      withModifier('animate', {
        transitionProperty: 'fill',
        transitionTimingFunction: Animation.transitionTimingFunction,
        transitionDuration: Animation.transitionDuration,
      }),

    !props.canAcceptPointerEvents &&
      withModifier('disable-pointer-events', {
        pointerEvents: 'none',
      }),

    props.isBlock &&
      withModifier('block', {
        display: 'block',
      }),

    withModifier(sanitizeClassName(`${props.size}x${props.size}`), {
      height: props.size,
      width: props.size,
    }),
    props.color &&
      withModifier(props.color, {
        fill: Colors[props.color],
      }),
  ],
});

// Renders in the event that an invalid icon name is used.
const MissingIcon = () => (
  <svg
    aria-label="Could not load icon"
    data-is-missing-icon="true"
    viewBox="0 0 24 24"
  >
    <rect height="24" width="24" x="0" y="0" />
  </svg>
);

type IconErrorBoundaryState = {
  didFailToLoad: boolean;
};

type IconErrorBoundaryProps = {
  children: React.ReactNode;
};

class IconErrorBoundary extends Component<
  IconErrorBoundaryProps,
  IconErrorBoundaryState
> {
  state = {
    didFailToLoad: false,
  };

  static getDerivedStateFromError() {
    return {
      didFailToLoad: true,
    };
  }

  render() {
    return this.state.didFailToLoad ? <MissingIcon /> : this.props.children;
  }
}

/**
 * Renders an `SVG` icon.
 *
 * All icons are normalized in size, and should be interchangeable without icon specific alignment tweaks.
 */
export const Icon = ({
  'aria-label': ariaLabel,
  color,
  isBlock,
  name,
  'aria-hidden': ariaHidden = true,
  canAcceptPointerEvents = true,
  shouldAnimateFill = true,
  size = 'small',
}: Props) => {
  const IconComponent = getIcon(name);

  let cssSize = size;
  switch (size) {
    case 'small':
      cssSize = '24px';
      break;
    case 'medium':
      cssSize = '36px';
      break;
    case 'large':
      cssSize = '48px';
      break;
  }

  const stylesOptions = {
    name,
    canAcceptPointerEvents,
    isBlock,
    color,
    shouldAnimateFill,
    size: cssSize,
  };

  return (
    <span
      aria-hidden={ariaHidden ? 'true' : undefined}
      aria-label={ariaLabel}
      css={Styles.root(stylesOptions)}
      data-testid="iconContainer"
      data-zds
    >
      <IconErrorBoundary>
        <IconComponent size={cssSize} color={color} name={name} />
      </IconErrorBoundary>
    </span>
  );
};

function getIcon(name: IconName) {
  try {
    if (name === 'miscAI') {
      return Icons['MiscAi'];
    }
    if (name === 'miscAIFill') {
      return Icons['MiscAiFill'];
    }
    if (name === 'navAIChatbot') {
      return Icons['NavAiChatbot'];
    }
    if (name === 'miscEvergreenAI') {
      return Icons['MiscEvergreenAi'];
    }

    const capitalizedIcon = `${name[0].toUpperCase()}${name.substring(1)}`;
    return Icons[capitalizedIcon as keyof typeof Icons] || MissingIcon;
  } catch {
    return MissingIcon;
  }
}
