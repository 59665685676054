import { URLS } from 'app/common/constants';

export const getNavLinksLeft = () => {
  const integrationLink = {
    href: URLS.buildIntegration,
    isVisible: true,
    label: 'How to build',
  };

  const fixedLinks = [
    {
      href: URLS.docs,
      isVisible: true,
      label: 'Docs',
    },
    {
      href: URLS.tools,
      isVisible: true,
      label: 'Tools',
    },
    {
      href: URLS.partnerProgram,
      isVisible: true,
      label: 'Partner program',
    },
    {
      href: URLS.resources,
      isVisible: true,
      label: 'Resources',
    },
  ];

  return [integrationLink, ...fixedLinks];
};

export const getNavLinksRight = (isLoadingUser: boolean, isAuthed: boolean) => {
  if (!isAuthed || isLoadingUser) {
    return [];
  }

  const myIntegrationsLink = {
    href: URLS.vbBase,
    isVisible: true,
    label: 'My integrations',
  };

  const zapTemplatesLink = {
    href: URLS.zapTemplates,
    isVisible: true,
    label: 'Zap templates',
  };

  return [myIntegrationsLink, zapTemplatesLink];
};
