import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavFunctions = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m6.572 12 4.545 4.545-3.535 3.537L2 14.5v-5L9.5 2h5l1.035 1.035L6.572 12Z"
    />
    <path
      fill="#2D2E2E"
      d="M12.883 7.455 16.42 3.92 22 9.5v5L14.5 22h-5l-1.035-1.035L17.428 12l-4.545-4.545Z"
    />
  </svg>
);
export default SvgNavFunctions;
