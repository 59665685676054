/** @jsx jsx */

import { jsx } from '@emotion/core';

import { Button } from '@zapier/design-system';

/*
 * This leverages our custom Link component to perform routing.
 */
function LinkButton(props: React.ComponentProps<typeof Button>) {
  const { href, target, ...buttonProps } = props;

  return <Button href={href} target={target} {...buttonProps} />;
}

export default LinkButton;
