/** @jsx jsx */

import { MouseEvent } from 'react';
import { Button, Link, Text } from '@zapier/design-system';
import { css, jsx } from '@emotion/core';

import { MEDIA_MIN_TABLET } from './constants';

const Styles = {
  loginLink: css`
    color: inherit !important;
    text-decoration: none !important;
    transition: all 0.2s ease-in-out !important;
    white-space: nowrap !important;
  `,
  root: (isLoading: boolean) => css`
    align-items: center;
    color: inherit;
    display: ${isLoading ? 'none' : 'flex'};
    justify-content: space-around;
    /* Prefer visibility to display to keep the header height. */
    ${[MEDIA_MIN_TABLET]} {
      justify-content: initial;
      gap: 16px;
    }
  `,
  signUpButton: css`
    display: none;
    ${[MEDIA_MIN_TABLET]} {
      display: inline-block;
    }
  `,
};

type Props = {
  isLoading: boolean;
  onClickLogIn?: (event: MouseEvent) => void;
  onClickSignUp?: (event: MouseEvent) => void;
  urlLogIn: string;
  urlSignUp: string;
};

function AuthButtons({
  isLoading,
  onClickLogIn,
  onClickSignUp,
  urlLogIn,
  urlSignUp,
}: Props) {
  return (
    <nav css={Styles.root(isLoading)}>
      <Link css={Styles.loginLink} href={urlLogIn} onClick={onClickLogIn}>
        <Text color="inherit">Log in</Text>
      </Link>
      <span css={Styles.signUpButton}>
        <Button color="brand" href={urlSignUp} onClick={onClickSignUp}>
          Sign up
        </Button>
      </span>
    </nav>
  );
}

export default AuthButtons;
