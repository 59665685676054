/** @jsx jsx */
import React, { useState } from 'react';

import { css, jsx } from '@emotion/react';
import { labelStyles } from '@zapier/style-encapsulation';
import { Icon } from '../../display/Icon';
import { FormLabel } from '../../forms/FormLabel';
import { Colors, Typography } from '../../../theme';
import { useOverflow } from '../../../hooks/useOverflow';
import { uniqueId } from '../../../utils/uniqueId';

type RenderInputOptions = {
  ariaDescribedBy?: string;
  id: string;
  isDisabled: boolean;
  isErrored: boolean;
  isRequired: boolean;
};

type RenderLabelProps = {
  htmlFor: string;
  isDisabled?: boolean;
  isErrored?: boolean;
  isRequired?: boolean;
  label: string;
  requiredText?: React.ReactNode;
};

export type Props = {
  /**
   * Optional error message for the field.
   * If it exists, the field will be considered to have an error.
   */
  error?: string;
  /**
   * The number of help text lines to show (defaults to 1).
   * Any extra text will be hidden behind a "more" button.
   */
  helpTextLineClamp?: number;
  /**
   * `id` for the input field. If this isn't supplied then one will be generated
   * to properly link the `label` to the input.
   */
  inputId?: string;
  /**
   * Whether a more compact version of the field should be rendered.
   */
  isCompact?: boolean;
  /**
   * Indicates whether the field is disabled.
   */
  isDisabled?: boolean;
  /**
   * Indicates whether the field is required.
   */
  isRequired?: boolean;
  /**
   * Text for the `label` element for this field.
   */
  label: string;
  /**
   * Function that returns the help text to be rendered beneath the input.
   */
  renderHelpText?: () => React.ReactNode;
  /**
   * Function that returns the rendered input field.
   */
  renderInput: (opts: RenderInputOptions) => React.ReactNode;
  /**
   * Function that returns the label to be rendered.
   */
  renderLabel?: (labelProps: RenderLabelProps) => React.ReactNode;
  /**
   * Optional text to render inside the required label.
   */
  requiredText?: React.ReactNode;
};

const Styles = labelStyles('Field', {
  root: css`
    display: grid;
    grid-gap: var(--zds-space-4, 5px);
  `,

  helpTextWrapper: ({ isCompact }: { isCompact: Props['isCompact'] }) => css`
    ${isCompact ? Typography.MinimalPrint1 : Typography.SmallPrint1}
  `,

  helpText: css`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    align-items: start;
    color: var(--zds-text-default, ${Colors.GrayWarm9});
  `,

  helpTextInner: css`
    padding-top: 1px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    // Without this truncation won't properly occur.
    * {
      display: inline;
      margin: 0;
    }
  `,

  helpTextToggle: css`
    cursor: pointer;
    text-decoration: underline;
    text-transform: lowercase;
    // Prevent help text toggle size from changing.
    width: 33px;
    &:hover,
    &:focus {
      outline: 1px solid var(--ui-primary, ${Colors.UiPrimaryStrongest});
      outline-offset: 1px;
      color: var(--ui-primary, ${Colors.UiPrimaryStrongest});
    }
  `,

  error: css`
    display: inline;
    color: var(--zds-status-error-stronger, ${Colors.StatusErrorStronger});
  `,
});

type HelpTextProps = {
  children: React.ReactNode;
  helpTextLineClamp: number;
};

/**
 * Component to render help text which can be expanded and collapsed.
 */
const HelpText = ({ children, helpTextLineClamp }: HelpTextProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOverflowing, setOverflowingNode] = useOverflow();

  // Only render toggle if it's overflowing.
  const toggle = isOverflowing && (
    <button
      aria-hidden="true"
      css={Styles.helpTextToggle}
      onClick={() => setIsOpen(!isOpen)}
      type="button"
      data-zds
    >
      {isOpen ? 'Less' : 'More'}
    </button>
  );
  const style = {
    WebkitLineClamp: isOpen ? undefined : helpTextLineClamp,
  };
  return (
    <div css={Styles.helpText} data-zds>
      <div
        css={Styles.helpTextInner}
        ref={(node) => setOverflowingNode(node as HTMLElement)}
        data-zds
        style={style}
      >
        <div data-zds>{children}</div>
      </div>
      {toggle}
    </div>
  );
};

/**
 * Wraps an `input` field and renders a `label` and optional help text.
 */
export const Field = ({
  label,
  renderInput,
  error = undefined,
  inputId = undefined,
  isCompact = false,
  isDisabled = undefined,
  isRequired = undefined,
  helpTextLineClamp = 1,
  renderHelpText = undefined,
  renderLabel = undefined,
  requiredText = undefined,
}: Props) => {
  const [id] = useState(inputId || uniqueId('Field-'));
  const describedById = `${id}-description`;
  const helpText = renderHelpText && renderHelpText();
  return (
    <div css={Styles.root} data-zds>
      <div data-zds>
        {renderLabel ? (
          renderLabel({
            htmlFor: id,
            isDisabled: isDisabled,
            isErrored: !!error,
            isRequired: isRequired,
            label: label,
            requiredText: requiredText,
          })
        ) : (
          <FormLabel
            alignItems="start"
            htmlFor={id}
            isDisabled={isDisabled}
            isErrored={!!error}
            isRequired={isRequired}
            requiredText={requiredText}
            size={isCompact ? 'compact' : 'small'}
          >
            {error && (
              <Icon
                color="StatusErrorStronger"
                isBlock={true}
                name="formXCircle"
                size={18}
              />
            )}
            <span data-zds>{label}</span>
          </FormLabel>
        )}
      </div>
      <div data-zds>
        {renderInput({
          ariaDescribedBy: helpText || error ? describedById : undefined,
          id,
          isDisabled: !!isDisabled,
          isErrored: !!error,
          isRequired: !!isRequired,
        })}
      </div>
      <div
        aria-live={error ? 'polite' : 'off'}
        css={Styles.helpTextWrapper({ isCompact })}
        id={describedById}
        data-zds
      >
        {(helpText || error) && (
          <HelpText helpTextLineClamp={helpTextLineClamp}>
            {error && <div css={Styles.error}>{error} </div>}
            {helpText}
          </HelpText>
        )}
      </div>
    </div>
  );
};
