import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavProductHub = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M2.75 3.75a1 1 0 0 1 1-1h2.5a1 1 0 0 1 1 1v2.5a1 1 0 0 1-1 1h-2.5a1 1 0 0 1-1-1v-2.5ZM2.75 10.75a1 1 0 0 1 1-1h2.5a1 1 0 0 1 1 1v2.5a1 1 0 0 1-1 1h-2.5a1 1 0 0 1-1-1v-2.5ZM3.75 16.75a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1h2.5a1 1 0 0 0 1-1v-2.5a1 1 0 0 0-1-1h-2.5ZM9.75 3.75a1 1 0 0 1 1-1h2.5a1 1 0 0 1 1 1v2.5a1 1 0 0 1-1 1h-2.5a1 1 0 0 1-1-1v-2.5ZM10.75 9.75a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1h2.5a1 1 0 0 0 1-1v-2.5a1 1 0 0 0-1-1h-2.5ZM9.75 17.75a1 1 0 0 1 1-1h2.5a1 1 0 0 1 1 1v2.5a1 1 0 0 1-1 1h-2.5a1 1 0 0 1-1-1v-2.5ZM17.75 2.75a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1h2.5a1 1 0 0 0 1-1v-2.5a1 1 0 0 0-1-1h-2.5ZM16.75 10.75a1 1 0 0 1 1-1h2.5a1 1 0 0 1 1 1v2.5a1 1 0 0 1-1 1h-2.5a1 1 0 0 1-1-1v-2.5ZM17.75 16.75a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1h2.5a1 1 0 0 0 1-1v-2.5a1 1 0 0 0-1-1h-2.5Z"
    />
  </svg>
);
export default SvgNavProductHub;
