import { InteractiveTag as InteractiveTagInternal } from './InteractiveTag';
import { InteractiveTagIcon } from './InteractiveTagIcon';
import { InteractiveTagContent } from './InteractiveTagContent';
import { InteractiveTagWrapper } from './InteractiveTagWrapper';

export { InteractiveTags } from './InteractiveTags';

export const InteractiveTag = Object.assign(InteractiveTagInternal, {
  Icon: InteractiveTagIcon,
  Content: InteractiveTagContent,
  Wrapper: InteractiveTagWrapper,
});
