/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Icon, IconName } from '../../display/Icon';
import { Link } from '../../navigation/Link';
import { Styles } from './Styles';

export type ButtonColor =
  | 'danger'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'icon-alt'
  | 'icon-ghost'
  | 'icon-primary'
  | 'icon-primary-dark'
  | 'icon-secondary'
  | 'icon-secondary-dark'
  | 'icon-tertiary'
  | 'brand'
  | 'brand-nav'
  | 'button-nav'
  | 'primary-dark'
  | 'secondary-dark'
  | 'number'
  | 'ghost';

export type Props = {
  /**
   * Optional reference to the `id` of an element that `Button` controls.
   * Often used in conjunction with `ariaExpanded`.
   */
  'aria-controls'?: string;
  /**
   * Optional `id` of element that describes `Button`.
   */
  'aria-describedby'?: string;
  /**
   * Optional `aria-expanded` attribute to indicate whether `Button`
   * controls content that can be expanded or collapsed, and whether that
   * content is expanded.
   */
  'aria-expanded'?: boolean;
  /**
   * Optional `aria-haspopup` attribute, indicating whether `Button` opens a popup.
   */
  'aria-haspopup'?: boolean;
  /**
   * Optional `aria-hidden` attribute indicating whether `Button` should be
   * hidden from screenreaders. Only use in rare cases where accessibility is
   * improved, such as if `Button` is redundant in a particular context.
   */
  'aria-hidden'?: boolean;
  /**
   * Optional `aria-label` to describe `Button`.
   */
  'aria-label'?: string;
  /**
   * Content to render within `Button`. Function children are supported.
   */
  children: React.ReactNode;
  /**
   * The color style to render for `Button`.
   */
  color?: ButtonColor;
  /**
   * Optionally render `Button` as `Link`.
   */
  href?: string;
  /**
   * The `target` attribute for `Button` when it renders as a `Link`.
   */
  target?: '_self' | '_blank' | '_parent' | '_top';
  /**
   * The `rel` attribute for `Button` when it renders as a `Link`.
   */
  rel?: string;
  /**
   * Whether or not `Button` is disabled. If `href` is supplied and
   * `disabled` is `true`, `Button` will render as a `button` instead
   * of as a `Link`.
   */
  disabled?: boolean;
  /**
   * The `tabindex` attribute to apply to `Button`. Should only be used
   * in very specific circumstances.
   */
  tabIndex?: number;
  /**
   * The `type` attribute for `Button` when it renders as a `button`.
   */
  type?: 'button' | 'submit' | 'reset';
  /**
   * Optional `for` attribute to apply to node.
   * If supplied, the rendered tag will be a `label`.
   */
  htmlFor?: string;
  /**
   * Optional icon to render before `children`.
   */
  iconBefore?: IconName;
  /**
   * Indicates whether `Button` should be the full width of its container.
   */
  isFullWidth?: boolean;
  /**
   * Renders `Button` in a pill shaped format. Note that this
   * should only be used for `IconButton`s.
   */
  isPill?: boolean;
  /**
   * Indicates whether `Button` should render as selected. Note
   * that this should only be used for `ButtonNavButton`s
   */
  isSelected?: boolean;
  /**
   * Indicates whether `Button` should render as a square,
   * making its height and width identical.
   */
  isSquare?: boolean;
  /**
   * Indicates whether `Button` should have a drop shadow.
   */
  hasShadow?: boolean;
  /**
   * Controls the size of `Button`, impacting its dimensions and typography.
   */
  size?: 'compact' | 'small' | 'medium' | 'large';
  /**
   * Optional `id` attribute to apply to `Button` so that another node can reference it.
   */
  id?: string;
  /**
   * Indicates whether `Button` is for presentation purposes only, i.e. whether it is
   * interactive. When `true`, `Button` is rendered as a `span` rather than as a `Link`
   * or `button`.
   */
  presentationOnly?: boolean;
  /**
   * Function to call when `Button` is clicked.
   */
  onClick?: React.MouseEventHandler;
  /**
   * Function to call when a key is pressed on `Button`.
   */
  onKeyDown?: React.KeyboardEventHandler;
};

type ButtonIconProps = {
  hasSibling: boolean;
  iconName: IconName;
  size: number;
};

const ButtonIcon = (props: ButtonIconProps) => (
  <span css={Styles.buttonIcon(props.hasSibling)} data-zds>
    <Icon
      canAcceptPointerEvents={false}
      isBlock={true}
      name={props.iconName}
      size={props.size}
    />
  </span>
);

const ButtonText = (props: { children: React.ReactNode }) => (
  <span css={Styles.buttonText} data-zds>
    {props.children}
  </span>
);

const ButtonContent = (props: Props) => {
  return (
    <span css={Styles.buttonContent} data-zds>
      {props.iconBefore && (
        <ButtonIcon
          hasSibling={!!props.children}
          iconName={props.iconBefore}
          size={24}
        />
      )}
      <ButtonText>{props.children}</ButtonText>
    </span>
  );
};

const getTag = (props: Props) => {
  if (props.presentationOnly) {
    return 'span';
  }
  if (props.htmlFor && !props.disabled) {
    return 'label';
  }
  if (props.href && !props.disabled) {
    return Link;
  }
  return 'button';
};

/**
 * Base component for `Button` and `IconButton`. Exists to define a shared
 * interface while allowing components like `Button` and `IconButton` to
 * expose a subset of props.
 */
export function BaseButton({
  disabled = false,
  isFullWidth = false,
  isPill = false,
  ...otherProps
}: Props) {
  const props = {
    disabled,
    isFullWidth,
    isPill,
    ...otherProps,
  };

  const ButtonTag = getTag(props);
  const isLink = ButtonTag === Link;

  return (
    <ButtonTag
      aria-controls={props['aria-controls']}
      aria-describedby={props['aria-describedby']}
      aria-disabled={props['disabled']}
      aria-expanded={props['aria-expanded']}
      aria-haspopup={props['aria-haspopup']}
      aria-hidden={props['aria-hidden']}
      aria-label={props['aria-label']}
      // @ts-ignore TS doesn't understand Link[color=null]
      color={isLink ? null : undefined}
      css={Styles.root}
      data-a11y-ignore={props.color === 'brand' ? 'color-contrast' : undefined}
      data-color={props.color}
      data-icon={props.iconBefore ? 'before' : undefined}
      data-full-width={props.isFullWidth ? '' : undefined}
      data-pill={props.isPill ? '' : undefined}
      data-selected={props.isSelected ? '' : undefined}
      data-shadowed={props.hasShadow ? '' : undefined}
      data-size={props.size}
      data-square={props.isSquare ? '' : undefined}
      data-zds
      disabled={props.disabled}
      htmlFor={props.htmlFor}
      href={isLink ? props.href : undefined}
      id={props.id}
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
      rel={isLink ? props.rel : undefined}
      role={!isLink ? 'button' : undefined}
      tabIndex={props.tabIndex}
      target={isLink ? props.target : undefined}
      type={ButtonTag === 'button' ? props.type : undefined}
    >
      <ButtonContent {...props} />
    </ButtonTag>
  );
}
