import React from 'react';
import { css, Global } from '@emotion/react';

type Props = {
  /**
   * @deprecated GTUltra no longer part of our font stack
   */
  includeUltra?: boolean;
  children?: React.ReactNode;
};

/**
 * A Helmet-like component to load Zinnia Fonts for NextJS applications.
 */
export const ZinniaFonts = ({ children }: Props) => {
  return (
    <React.Fragment>
      <Global
        styles={css`
          @font-face {
            font-family: Degular Display;
            src: url('https://fonts.zapier.com/Degular/DegularDisplay-Medium.woff2')
              format('woff2');
            font-display: swap;
            font-weight: 500;
          }
          @font-face {
            font-family: Degular Display;
            src: url('https://fonts.zapier.com/Degular/DegularDisplay-Semibold.woff2')
              format('woff2');
            font-display: swap;
            font-weight: 600;
          }
          @font-face {
            font-family: Degular Display;
            src: url('https://fonts.zapier.com/Degular/Degular_Display-Bold.woff2')
              format('woff2');
            font-display: swap;
            font-weight: 700;
          }
        `}
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
      />
      {children}
    </React.Fragment>
  );
};
